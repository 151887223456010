import Home from "./home"
import AboutUs from "./aboutus"
import Blog from "./blog"
import NewsPage from "./news"
const base_url = "/home/"
// const base_url = "/"        //in order to run build on github pages, mutual comment these lines.

let routes = {
  "home" : {
    path : "/",
    url : "/",
    component : (props) => <Home {...props} />
  },
  "aboutus" : {
    path : base_url + "aboutus",
    url : "/aboutus",
    component : (props) => <AboutUs {...props} />
  },
  "blog" : {
    path : base_url + "blog",
    url : "/blog",
    component : (props) => <Blog {...props} />
  },
  "news" : {
    path : base_url + "blog/",
    url : "/blog/:id",
    component : (props) => <NewsPage {...props} />
  },
}

export default routes