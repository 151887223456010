import { ConstructionOutlined } from '@mui/icons-material'
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import ModuleController from '../../../controllers/module_controller'
import {Stepper, Step, StepLabel, Fade, StepContent } from "@mui/material"
import { Col } from 'react-bootstrap'
import QuestionController from '../../../controllers/question_controller'
import QuizGroup from '../../../components/quiz_group'
import RichTextEditor from 'react-rte'
import MultipleChoiceQuestion from '../../../components/multiple_choice_question'
import routes from '../routes'
import QuestionCreationController from '../../../controllers/question_creation_controller'
import NotFinishedWindow from './NotFinishedWindow'


function Lesson(props) {
    let windowInfo = props.windowInfo
    let user = props.user
    let { pathname, state } = useLocation()
    let { moduleId, lessonId } = useParams()
    let navigate = useNavigate()

    const lessonIdRef = useRef(lessonId)
    const [content, setContent] = useState(new ModuleController())
    const [activePage, setActivePage] = useState(0)
    const [activePageId, setActivePageId] = useState(null)
    const [loading, setLoading] = useState(true)
    const [nextPageLabel, setNextPageLabel] = useState("Successivo")
    const [previousPageLabel, setPreviousPageLabel] = useState("Precedente")
    const [showNotFinishedWindow, setShowNotFinishedWindow] = useState(false)
    const [skipChapter, setSkipChapter] = useState(false)
    const [activateStepper, setActivateStepper] = useState(true)
    const [questionsCompletion, setQuestionsCompletion] = useState({
        lessonId: lessonIdRef.current,
        completion: {}
    })

    useEffect(() => {
       closeNotFinishedWindow() 
    },[activePageId])

    function areAllQuizzesPassed(){
        // let keys = Object.entries(questionsCompletion.completion).filter((key, value)=>key !== "").map((key,value)=>value===true)
        let keys = Object.values(questionsCompletion.completion)
        return questionsCompletion.lessonId === lessonIdRef.current && (keys.length === 0 ||  keys.every((item)=>item))
    }

    useEffect(() => {
        let lessons = content.getAllPages(lessonIdRef.current)
        // checking if all quiz have been done
        for(let lesson of Object.values(lessons).sort((a,b) => a['position'] - b['position'])) {
            let position = lesson['position'] - 1
            if((position <= activePage) && (lesson['type'] == "quiz")) {
                let quiz = lesson['content']['question']
                if(quiz instanceof QuestionController && (quiz && quiz.getSelectedChoices().length == 0) && !questionsCompletion.completion[quiz.id]?.isCompleted?.()) {
                    setActivePage(position)
                    return
                }
            }
        }
        let doChange = true
        // managing chapter change
        if(!loading && activePage >= Object.keys(lessons).length) {
            // check finisched quiz or skipped
            if(areAllQuizzesPassed()){
                // console.log("CAPITOLO COMPLETATO");
                content.setChapterFinished(lessonIdRef.current)
                loadNewChapter()
            }else{
                if(skipChapter){
                    setSkipChapter(false)
                    setShowNotFinishedWindow(false)
                    // console.log("CAPITOLO SKIPPATO");
                    loadNewChapter()
                }else{
                    doChange = false
                    setShowNotFinishedWindow(true)
                    // console.log("CAPITOLO NON COMPLETATO");
                }
            }
        } else if(!loading && activePage == -1) {
            let chapters = content.getModules()
            let position = content.getModuleById(lessonIdRef.current)['position']
            let [nextChapter] = Object.values(chapters).filter(item => item['position'] == position - 1)
            if(nextChapter != undefined) {
                // TODO: set finished
                pathname = pathname.substring(0, pathname.lastIndexOf("/")) + "/" + nextChapter['id']
                lessonIdRef.current = nextChapter['id']
                navigate(pathname, { state : { module: content.exportInfo() }})
            } else {
                // first chapter just loaded
            }
        }

        // managin page overflow
        if(activePage < 0) {
            setActivePage(0)
        } else if(activePage == 0 && content.getModuleById(lessonIdRef.current)?.position == 1) {
            setPreviousPageLabel("")
        } else if(activePage == 0){
            setPreviousPageLabel("Capitolo precedente")
        } else {
            setPreviousPageLabel("Precedente")
        }

        if(activePage > Object.keys(lessons).length) {
            setActivePage(Object.keys(lessons).length - 1)
        } else if(doChange && activePage == (Object.keys(lessons).length - 1) && content.getModuleById(lessonIdRef.current)?.position == Object.keys(content.getModules()).length) {
            setNextPageLabel("Fine")
        } else if(doChange && activePage == (Object.keys(lessons).length - 1)){
            setNextPageLabel("Prossimo capitolo")
        } else {
            if(doChange)
                setNextPageLabel("Successivo")
        }

        doChange = true;

    }, [activePage])
    
    function closeNotFinishedWindow(){
        setShowNotFinishedWindow(false)
    }

    function loadNextPage(){
        setSkipChapter(true)
        setActivePage(activePage + 1)
    }

    function loadNewChapter(){
        // load new chapter
        let chapters = content.getModules()
        let position = content.getModuleById(lessonIdRef.current)['position']
        let [nextChapter] = Object.values(chapters).filter(item => item['position'] == position + 1)
        if(nextChapter != undefined) {
            pathname = pathname.substring(0, pathname.lastIndexOf("/")) + "/" + nextChapter['id']
            lessonIdRef.current = nextChapter['id']
            navigate(pathname, { state : { module: content.exportInfo() }})
        } else {
            // chapters finished
            pathname = pathname.substring(0, pathname.lastIndexOf("/")) 
            navigate(pathname, { state : { module: content.exportInfo() }})
        }
    }
    
    useEffect(() => {
        // not first load
        if(!loading) {
            lessonIdRef.current = pathname.substring(pathname.lastIndexOf("/") + 1)
            setActivePage(0)
            setLoading(true)
            let updateMode = true
            if(Object.keys(content.getAllPages(lessonIdRef.current)).length == 0) {
                content.loadNote(lessonIdRef.current, updateMode)
            }
            setLoading(false)
        }
    }, [pathname])
    
    useEffect(() => { 
        setQuestionsCompletion({
            lessonId: lessonIdRef.current,
            completion: {}
        }) 
        Object.values(content.getAllPages(lessonIdRef.current)).map(item => {
            if(item.type === 'quiz'){
                let tmpQuestions = {...questionsCompletion}
                if(item.content instanceof QuestionController && item.content.id !== '' && !tmpQuestions.completion[item.content.id])
                    tmpQuestions.completion[item.content.id] = false
                else if(item.content instanceof QuestionCreationController && item.content.question.id !== '' && !tmpQuestions.completion[item.content.question.id])
                    tmpQuestions.completion[item.content.question.id] = false
                
                setQuestionsCompletion(tmpQuestions)
            }
        })
    },[lessonIdRef, content])
    
    useEffect(() => {

        content.setState(setContent)
        if(state?.module != undefined) {
            content.load(state.module, false)
        } else {
            let fullGet = false
            content.loadById(moduleId, fullGet)
        }
        let updateMode = true
        try{
            content.loadNote(lessonIdRef.current, updateMode)
        } catch {
            navigate(routes.single_module.path)
        }
        setLoading(false)
    }, [])

    return (
        <Fade in={true}>
            <div id="module_pages">
                <div className="header text-center centered">
                    <h6 className="title">{content.getTitle()}</h6>
                    <div className="separator"></div>
                    <h2>{content.getModuleTitle(lessonIdRef.current)}</h2>
                    <div className="separator"></div>
                    <br/>
                </div>
                <Stepper
                activeStep={activePage < Object.values(content.getAllPages(lessonIdRef.current)).length? activePage:Object.values(content.getAllPages(lessonIdRef.current)).length-1}
                alternativeLabel={!(window && windowInfo.mobileMode)}
                orientation={windowInfo && windowInfo.mobileMode ? "vertical" :"horizontal"}>
                    
                    {
                        Object.values(content.getAllPages(lessonIdRef.current))
                        .sort((a,b) => a['position'] > b['position'] ? 1 : -1)
                        .map(a => a.id)
                        .map((pageId, index) => {
                                if(activePage == index && activePageId != pageId) setActivePageId(pageId) 
                                return  (
                                    <Step key={index} className="step" onClick={() => setActivePage(index)}>
                                        <Fade in={true} style={{ transitionDuration: "1000ms"}}><StepLabel>{content.getAllPages(lessonIdRef.current)[pageId].type === 'quiz'? 'Quiz': ('Lezione ' + content.getAllPages(lessonIdRef.current)[pageId]['position'])}</StepLabel></Fade>
                                        {
                                            windowInfo && windowInfo.mobileMode ?
                                            <StepContent>
                                                
                                                {
                                                    content.getPageType(lessonIdRef.current, activePageId) == 'quiz'  ?
                                                        <div className="mt-3">
                                                            <MultipleChoiceQuestion user={user} question={content.getPageContent(lessonIdRef.current, activePageId).question} completion={[questionsCompletion, setQuestionsCompletion]}>
                                                                {
                                                                    showNotFinishedWindow?
                                                                    <NotFinishedWindow 
                                                                        show={showNotFinishedWindow} handleClose={closeNotFinishedWindow} 
                                                                        handleNextPage={loadNextPage} 
                                                                    />
                                                                    : ""
                                                                }
                                                            </MultipleChoiceQuestion>
                                                        </div> 
                                                    :
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: RichTextEditor.createValueFromString(content.getPageContent(lessonIdRef.current, activePageId), 'markdown').toString("html")}}
                                                            className="markdown_content"
                                                        />
                                                }
                                                <Col md="11" className="mx-auto">
                                                
                                                    <div className="display_inline space_between">
                                                       {
                                                            previousPageLabel.trim() !== ""?
                                                                <button
                                                                    className="navigation_button button"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setActivePage(activePage - 1)
                                                                    }}
                                                                >
                                                                    {previousPageLabel}
                                                                </button>
                                                            : ""
                                                        }
                                                        <button
                                                        className="navigation_button button"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setActivePage(activePage + 1)
                                                        }}>{nextPageLabel}</button>
                                                    </div>
                                                </Col>
                                            </StepContent> : 
                                            ""
                                        }
                                    </Step>
                                )
                            }
                                
                        )
                    }
                </Stepper>
                {
                    windowInfo && !windowInfo.mobileMode ?
                        <div className="content_container">
                            <Col md="8" className="mx-auto mt-5">
                                {
                                    content.getPageType(lessonIdRef.current, activePageId) == 'quiz' ?
                                        <>
                                            <MultipleChoiceQuestion user={user} question={content.getPageContent(lessonIdRef.current, activePageId).question} completion={[questionsCompletion, setQuestionsCompletion]}>
                                                {
                                                    showNotFinishedWindow?
                                                    <NotFinishedWindow 
                                                        show={showNotFinishedWindow} handleClose={closeNotFinishedWindow} 
                                                        handleNextPage={loadNextPage} 
                                                    />
                                                    : ""
                                                }
                                            </MultipleChoiceQuestion>
                                            
                                        </>
                                    :
                                        <div
                                            dangerouslySetInnerHTML={{ __html: RichTextEditor.createValueFromString(content.getPageContent(lessonIdRef.current, activePageId), 'markdown').toString("html")}}
                                            className="markdown_content"
                                        />
                                }
                            </Col>
                            <Col md="11" className="mx-auto">
                                <div className="display_inline space_between">
                                    {
                                        previousPageLabel.trim() !== ""?
                                            <button
                                                className="navigation_button button"
                                                onClick={() => setActivePage(activePage - 1)}
                                            >
                                                {previousPageLabel}
                                            </button>
                                        : <div></div>
                                    }
                                    <button
                                        className="navigation_button button"
                                        onClick={() => setActivePage(activePage + 1)}
                                    >
                                        {nextPageLabel}
                                    </button>
                                </div>
                            </Col>
                        </div> : 
                        <br/>
                }
            </div>
        </Fade>
    )
}

export default Lesson
