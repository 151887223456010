import React, {useEffect, useRef, useState} from 'react'
import NewsListController from "../../controllers/news_list_controller"
import { Carousel, Container, Col } from 'react-bootstrap'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Chip, Fade } from '@material-ui/core';
import News from '../../components/news_card';
import routes from './routes';
import { useNavigate } from 'react-router';

function Blog(props) {
  let windowInfo = props.windowInfo

  let navigate = useNavigate()

  const [news, setNews] = useState(new NewsListController())
  const [selectedCategory, setSelectedCategory] = useState(undefined)
  
  function loadMoreNews() {
    news.loadNewsPerCategory(selectedCategory)
  }

  useEffect(() => {
    news.setState(setNews)
    news.loadCategories().then(() => setSelectedCategory(Object.keys(news.getCategories())[0]))
    news.loadNewsPerCategory("economia")
  }, [])

  useEffect(() => {
    news.loadNewsPerCategory(selectedCategory)
  }, [selectedCategory])
  
  return (
    <div className="blog">
      <br/>
      <Container>
        <div className="block">
          <Carousel>
            {
              news.getNewsPerCategory("economia").map(news => (
                <Carousel.Item>
                  <div className="carousel_item">
                    <div className="gradient"></div>
                    <img className="image" src={news.getWallpaper()}/>
                    <Col md="8" className="content mx-auto">
                      <h3 className="title">{news.getTitle()}</h3>
                      <h6 className="author">{news.getAuthor()}</h6>
                      <br/>
                      <div className="centered">
                        <RemoveRedEyeIcon className="orange_icon open_news bounce" onClick={() => navigate(routes.news.path + news.getId())}/>
                      </div>
                      <br/>
                    </Col>
                  </div>
                </Carousel.Item>
              ))
            }
          </Carousel>
        </div>

        <br/>
        <br/>

        <div className="per_category">
            <h2>Per categoria</h2>
            <div className="horizontal_scroller">
              {
                Object.keys(news.getCategories()).map(categorySlug => (
                  <Chip
                  label={news.getCategories()[categorySlug]}
                  className={"my_chip " + (categorySlug == selectedCategory ? "orange" : "")}
                  onClick={() => setSelectedCategory(categorySlug)}/>
                ))
              }
            </div>
            <div className="news_list">
              {
                news.getNewsPerCategory(selectedCategory).map(news => (
                  <Fade in={true}>
                    <div>
                      <News
                      content={news}
                      windowInfo={windowInfo}
                      layout="squared"
                      customRedirectUrl={routes.news.path}/>
                    </div>
                  </Fade>
                ))
              }
            </div>
            <br/>
            <br/>
            <div className="centered">
              {
                news.moreNews(selectedCategory) ? 
                  <h6 className="load_more" onClick={loadMoreNews}>Carica di più</h6> : 
                  ""
              }
            </div>
        </div>
      </Container>
    </div>
  )
}

export default Blog