import WebinarModel from "../models/webinar";
import { api_url } from "../App"
import $ from "jquery"
import { beforeSendCallback } from "../utils";

class WebinarController extends WebinarModel {
    constructor() {
        super()
        this.state = undefined
        this.overrideState = undefined
    }

    setState(state, auto_save = true) {
        this.state = state
        if(auto_save)   this.updateInfo()
    }

    setOverrideState(overrideState, auto_save = true) {
        this.overrideState = overrideState
        if(auto_save)   this.updateInfo()
    }

    updateInfo() {
        if(this.state != undefined) {
            let newIntstance = new WebinarController()
            Object.assign(newIntstance, this)
            this.state(newIntstance)
          }
        if(this.overrideState != undefined)
            this.overrideState()
    }

    async loadById(id) {
        //let accessToken = window.localStorage.getItem('accessToken')

        await $.ajax({
            type: "GET",
            url: api_url + "webinar/" + id,
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
            success: async (data) => {
                let auto_save = false
                this.setId(data['slug'], auto_save)
                this.setWallpaper(data['coverImage'], auto_save)
                this.setDescription(data['description'], auto_save)
                this.setEventChatIframe(data['eventChatIframe'], auto_save)
                this.setEventId(data['eventId'], auto_save)
                this.setEventVideoIframe(data['eventVideoIframe'], auto_save)
                this.setFInalVideoId(data['finalVideoId'], auto_save)
                this.setFinalVideoIFrame(data['finalVideoIframe'], auto_save)
                this.setGuest(data['guest'], auto_save)
                this.setGuestLink(data['guestLink'], auto_save)
                this.setIsFree(data['isFree'], auto_save)
                this.setDate(data['liveDatetime'].replaceAll(" ", "T").slice(0, this.getDate().length - 3), auto_save)
                this.setSociety(data['society'], auto_save)
                this.setSocietyLink(data['societyLink'], auto_save)
                this.setTitle(data['title'], auto_save)
                this.updateInfo()
            }
        })
    }

    getId() { return this.id }
    getTitle() { return this.title }
    getDescription() { return this.description }
    getDate() { return this.date }
    getAuthors() { return this.authors }
    getWallpaper() { return this.wallpaper }
    getSociety() { return this.society }
    getSocietyLink() { return this.societyLink }
    getGuest() { return this.guest }
    getGuestLink() { return this.guestLink }
    getIsFree() { return this.isFree }
    getEventId() { return this.eventId }
    getEventVideoIframe() { return this.eventVideoIframe }
    getEventChatIframe() { return this.eventChatIframe }
    getFinalVideoIframe() { return this.finalVideoIframe }
    getFinalVideoId() { return this.finalVideoId }
    
    
    setId(id, auto_save = true) {
        this.id = id
        if(auto_save)   this.updateInfo()
    }

    setTitle(title, auto_save = true) {
        this.title = title
        if(auto_save)   this.updateInfo()
    }

    setDescription(description, auto_save = true) {
        this.description = description
        if(auto_save)   this.updateInfo()
    }

    setDate(date, auto_save = true) {
        this.date = date
        if(auto_save)   this.updateInfo()
    }
    
    setAuthors(authors, auto_save = true) {
        this.authors = authors
        if(auto_save)   this.updateInfo()
    }

    setWallpaper(wallpaper, auto_save = true) {
        this.wallpaper = wallpaper
        if(auto_save)   this.updateInfo()
    }

    setSociety(society, auto_save = true) {
        this.society = society
        if(auto_save)   this.updateInfo()
    }

    setSocietyLink(societyLink, auto_save = true) {
        this.societyLink = societyLink
        if(auto_save)   this.updateInfo()
    }

    setGuest(guest, auto_save = true) {
        this.guest = guest
        if(auto_save)   this.updateInfo()
    }

    setGuestLink(guestLink, auto_save = true) {
        this.guestLink = guestLink
        if(auto_save)   this.updateInfo()
    }

    setIsFree(isFree, auto_save = true) {
        this.isFree = isFree
        if(auto_save)   this.updateInfo()
    }

    setEventId(eventId, auto_save = true) {
        this.eventId = eventId
        if(auto_save)   this.updateInfo()
    }

    setEventVideoIframe(eventVideoIframe, auto_save = true) {
        this.eventVideoIframe = eventVideoIframe
        if(auto_save)   this.updateInfo()
    }

    setEventChatIframe(eventChatIframe, auto_save = true) {
        this.eventChatIframe = eventChatIframe
        if(auto_save)   this.updateInfo()
    }

    setFinalVideoIFrame(finalVideoIframe, auto_save = true) {
        this.finalVideoIframe = finalVideoIframe
        if(auto_save)   this.updateInfo()
    }

    setFInalVideoId(finalVideoId, auto_save = true) {
        this.finalVideoId = finalVideoId
        if(auto_save)   this.updateInfo()
    }
    
    checkContentValidity() {
        // wallpaper check 
        if(this.getWallpaper().replaceAll(" ", "") == "") return { error : true, message : "Wallpaper non inserito"}
        // date check
        if(this.getDate() == "") return { error : true, message : "Data non inserita"}
        // title check
        if(this.getTitle().replaceAll(" ", "") == "") return { error : true, message : "Titolo non inserito"}
        // description check
        if(this.getDescription().replaceAll(" ", "") == "") return { error : true, message : "Descrizione non inserita"}
        // check society
        if(this.getSociety().replaceAll(" ", "") == "") return { error : true, message : "Società non inserita"}
        // check societyLink
        if(this.getSocietyLink().replaceAll(" ", "") == "") return { error : true, message : "Link Società non inserito"}
        // check eventId
        if(this.getEventId().replaceAll(" ", "") == "") return { error : true, message : "Id evento non inserito"}
        // check guest
        if(this.getGuest().replaceAll(" ", "") == "") return { error : true, message : "Guest non inserito"}
        // check guestLink
        if(this.getGuestLink().replaceAll(" ", "") == "") return { error : true, message : "Link Guest non inserito"}
        // check eventId        
        if(this.getGuestLink().replaceAll(" ", "") == "") return { error : true, message : "Id evento non inserito"}
        // check eventVideoIframe
        // if(this.getEventVideoIframe().replaceAll(" ", "") == "") return { error : true, message : "Iframe del video dell'evento non inserito"}
        // check eventChatIframe
        // if(this.getEventChatIframe().replaceAll(" ", "") == "") return { error : true, message : "Iframe della chat non inserito"}
        // check finalVideoIframe
        // if(this.getFinalVideoIframe().replaceAll(" ", "") == "") return { error : true, message : "Iframe del video finale non inserito"}
        // check finalVideoId
        // if(this.getFinalVideoId().replaceAll(" ", "") == "") return { error : true, message : "Id del video finale non inserito"}
        return { error : false, message : "Ok"}
    }
}

export default WebinarController