import FieldsOfInterestListController from "../controllers/field_of_interest_list_controller"

class User {
    constructor() {
        this.accessToken = ""
        this.refreshToken = ""
        this.name = ""
        this.surname = ""
        this.birthday = ""
        this.email = ""
        this.username = ""
        this.password = ""
        this.codice_invito = ""
        this.avatar = null
        this.xp = ""
        this.consecutiveDaysJoin = ""
        this.league = ""
        this.language = ""
        this.role = []
        this.createdCodes = []
        this.interests_field = new FieldsOfInterestListController()
        this.isPremium = false
    }

    setAccessToken(accessToken) {this.accessToken = accessToken}
    setRefreshToken(refreshToken) {this.refreshToken = refreshToken}
    setName(name) {this.name = name}
    setSurname(surname) {this.surname = surname}
    setBirthday(birthday) {this.birthday = birthday}
    setEmail(email) {this.email = email}
    setUsername(username) {this.username = username}
    setPassword(password) {this.password = password}
    setCodiceInvito(codice_invito) {this.codice_invito = codice_invito}
    setAvatar(avatar) {this.avatar = avatar}
    setXP(xp) {this.xp = xp}
    setLanguage(language) {this.language = language}
    setLeague(league) {this.league = league}
    setConsecutiveDaysJoin(consecutiveDaysJoin) {this.consecutiveDaysJoin = consecutiveDaysJoin}
    setRole(role) {this.role = role}
    setCreatedCodes(createdCodes) { this.createdCodes = createdCodes}
    setInterestsField(interests_field) { this.interests_field = interests_field}
    setIsPremium(isPremium) { this.isPremium = isPremium}

    getAccessToken() {return this.accessToken}
    getRefreshToken() {return this.refreshToken}
    getName() {return this.name}
    getSurname() {return this.surname}
    getBirthday() {return this.birthday}
    getEmail() {return this.email}
    getUsername() {return this.username}
    getPassword() {return this.password}
    getCodiceInvito() {return this.codice_invito}
    getAvatar() {return this.avatar}
    getXP() {return this.xp}
    getLanguage() {return this.language}
    getLeague() {return this.league}
    getConsecutiveDaysJoin() {return this.consecutiveDaysJoin}
    getRole() {return this.role}
    getCreatedCodes() {return this.createdCodes}
    getInterestsField() { return this.interests_field }
    getIsPremium() { return this.isPremium }
}

export default User