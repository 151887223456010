import React from 'react';
import { Fade } from 'react-bootstrap';

function NotFinishedWindow(props){
    return (
        <Fade in={props.show}  style={{ transitionDuration: "800ms" }}>
            <div className="verify_answer" >
                <h2>Devi prima finire di rispondere alle domande</h2>
                <div className="display_inline space_between">
                    <button className="navigation_button button" onClick={(e)=> props.handleClose()}>
                        OK 
                    </button>
                    <button className="navigation_button button" onClick={(e)=> { e.stopPropagation(); props.handleNextPage();}}>
                        Procedi comunque
                    </button>
                </div>
                
            </div>
            
        </Fade>
    )
}
export default NotFinishedWindow