import React from 'react'
import "./style.css"
import routes from '../../routes'
import { useNavigate } from 'react-router'
import { Container } from '@material-ui/core'
import { Col } from 'react-bootstrap'

function StaticHome() {
    let navigate = useNavigate()

    return (
        <div id="static_home">
            <div className="container">
                <br/>
                <h1 className="m-0">Beta testing Finanz</h1>
                <Col md="6">
                    <p>-> <a onClick={() => navigate("/home")}>Pagina home statica</a></p>
                    <p>-> <a onClick={() => navigate("/dashboard")}>Accedi alla dashboard</a></p>
                </Col>
            </div>
        </div>
    )
}

export default StaticHome
