import CourseModel from "../models/course"
import QuestionCreationController from "./question_creation_controller"
import QuestionController from "./question_controller"
import $ from "jquery"
import {api_url} from "../App"
import { beforeSendCallback } from "../utils"

class CourseController extends CourseModel {
    constructor() {
        super()
        this.state = undefined
        this.overrideState = undefined
    }

    setState(state) { this.state = state }
    
    setOverrideState(callback) { this.overrideState = callback }

    loadInfo({id = "", title = "", argument = "", description = "", presentationVideo = "", presentationVideoId = "", professors = "", syllabus = "", publishDate = "", content = {}, time = "", star = "", wallpaper = "", offeredBy = "", author = "", n_video = 0, comments = [], quiz_type = "course", priority = 1, lessonCheckpoint = "", lastViewedLesson = ""}) {
        this.id = id
        this.title = title
        this.argument = argument
        this.presentationVideo = presentationVideo
        this.presentationVideoId = presentationVideoId
        this.professors = professors
        this.syllabus = syllabus
        this.description = description
        this.publishDate = publishDate
        this.time = time
        this.star = star
        this.wallpaper = wallpaper
        this.offeredBy = offeredBy
        this.author = author
        this.n_video = n_video
        this.content = content
        this.comments = comments
        this.quiz_type = quiz_type
        this.priority = priority

        //*** A.Crespi */
        this.lessonCheckpoint = lessonCheckpoint
        this.lastViewedLesson = lastViewedLesson
    }

    load(info, creationMode = true) {
        let content = JSON.parse(JSON.stringify(info['content']))
        this.loadInfo(info)

        content = info['content']

        if(content != undefined) {
            for(let chapterId in content) {
                for(let lessonId in this.getLessonsByChapter(chapterId)) {
                    for(let quizId in this.getLessonQuiz(chapterId, lessonId)) {
                        let quizObj = this.getSpecificQuiz(chapterId, lessonId, quizId)
                        if(quizObj['selectedChoices'] == undefined) quizObj = quizObj['question']
                        let quizController
                        if(true) {
                            quizController = new QuestionCreationController()
                            quizController.setOverrideState((() => this.updateInfo()).bind(this))
                            quizController.question.setId(quizObj['id'])
                            quizController.question.setTitle(quizObj['title'])
                            quizController.question.setImage(quizObj['image'])
                            for(let answerId of Object.keys(quizObj['choices'])) {
                                quizController.question.addChoice(quizObj['choices'][answerId], answerId)
                            }
                            quizController.question.setSelectedChoices(quizObj['selectedChoices'])
                        } else {
                            quizController = new QuestionController()
                            quizController.setOverrideUpdateInfo((() => this.updateInfo()).bind(this))
                            quizController.load({...quizObj.question, ...quizObj})
                        }
                        content[chapterId]['lessons'][lessonId]['quiz'][quizId] = quizController
                    }
                }
            }
        }

        this.setContent(content)
        this.updateInfo()
    }

    async loadById(courseId, allInfo = true) {
        // getting general info
        let info = await this.getGeneralInfo(courseId)
        if(info == undefined) throw Error()

        // setting general info 
        let course = info['course']
        let chapters = info['chapters']

        let argument = {
            id: course['argument']['slug'],
            title: course['argument']['title'],
            description: course['argument']['description'],
        }

        this.setArgument(argument)
        this.setId(course['slug'])
        this.setWallpaper(course['coverImageLink'])
        this.setTitle(course['title'])
        this.setDescription(course['description'])
        this.setPresentationVideo(course['trailerIframe'])
        this.setPresentationVideoId(course['trailerId'])
        this.setPriority(course['priority'])
        this.setOfferedBy(course['authors']) // setting authors account

        // setting chapters
        let nextLesson = 0  //0 -> course not started; -1 -> course ended; <string> -> course not finished
        let lastLesson = 0
        let overrideNext = false
        for(let chapter of chapters) {
            this.addChapter(chapter)

            // getting lessons per chapter
            let lessons = Object.values(chapter['lessons'])

            for(let i = 0; i < lessons.length; i++) {
                let lesson = lessons[i]
                if(overrideNext){   //if previous was finished, than set this lesson as checkpoint
                    nextLesson = [chapter['slug'], lesson['slug']]
                    overrideNext = false
                }
                if(lesson['isFinished']){    // if this lesson is finished...
                    lastLesson = [chapter['slug'], lesson['slug']]
                    if(i === lessons.length-1)  //...but is also the last lesson of the course, set checkpoint as 'course finished'
                        nextLesson = -1
                    else
                        overrideNext = true     //...the next will be the checkpoint
                }
                    
                if(allInfo) {
                    let lessonSlug = lesson['slug']
                    let lessonInfo = await this.getLessonFromServer(lessonSlug)
                    this.addLesson(chapter['slug'], lessonInfo)
                }else 
                    this.addLesson(chapter['slug'], lesson)
            }

            this.setLastViewedLesson(lastLesson, false)
            this.setLessonCheckpoint(nextLesson, false)
        }
    }

    // get course title, description, chapters and argument
    async getGeneralInfo(courseId) {
        //let accessToken = window.localStorage.getItem('accessToken')
        let info = undefined

        await $.ajax({
            type: "GET",
            url: api_url + "course/" + courseId,
            accepts: "application/json",
            contentType: "application/json",
            beforeSend: beforeSendCallback,
            success: (data) => info = data
        })

        return info
    }

    async getLessonFromServer(lessonId) {
        //let accessToken = window.localStorage.getItem('accessToken')
        let info = undefined

        await $.ajax({
            type: "GET",
            url: api_url + "course/lesson/" + lessonId,
            accepts: "application/json",
            contentType: "application/json",
            beforeSend: beforeSendCallback,
            success: (data) => info = data['lesson']
        })

        return info
    }

    async loadLessonFromServer(chapterId, lessonId) {
        let lessonInfo = await this.getLessonFromServer(lessonId)
        this.addLesson(chapterId, lessonInfo)
    }

    async setReadLesson(chapterId, lessonId) {
        //let accessToken = window.localStorage.getItem('accessToken')

        await $.ajax({
            type: "POST",
            url: api_url + "/course/lesson/" + lessonId +"/finish",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend: beforeSendCallback,
            success: () => {
                let lesson = this.getLesson(chapterId, lessonId)
                lesson['isFinished'] = true
                this.updateInfo()
            }
        })
    }

    async getThumbnail(videoId) {
        await $.ajax({
            type: "GET",
            url: "https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + videoId,
            // success: (data) => console.log(data)
        })
    }
    
    updateInfo() {
        if(this.state != undefined) {
            let newIntstance = new CourseController()
            Object.assign(newIntstance, this)
            this.state(newIntstance)
          }
        else if(this.overrideState != undefined)
            this.overrideState()
    }

    // Setter
    setId(id, _auto_save = true) {
        this.id = id
        if(_auto_save) this.updateInfo()
    }
    setTitle(title, _auto_save = true) {
        this.title = title
        if(_auto_save) this.updateInfo()
    }
    setArgument(argument, _auto_save = true) {
        this.argument = argument
        if(_auto_save) this.updateInfo()
    }
    setPresentationVideo(presentationVideo, _auto_save = true) {
        this.presentationVideo = presentationVideo
        if(_auto_save) this.updateInfo()
    }
    setPresentationVideoId(presentationVideoId, _auto_save = true) {
        this.presentationVideoId = presentationVideoId
        if(_auto_save) this.updateInfo()
    }
    setProfessors(professors, _auto_save = true) {
        this.professors = professors
        if(_auto_save) this.updateInfo()
    }
    setSyllabus(syllabus, _auto_save = true) {
        this.syllabus = syllabus
        if(_auto_save) this.updateInfo()
    }
    setDescription(description, _auto_save = true) {
        this.description = description
        if(_auto_save) this.updateInfo()
    }
    setPublishDate(publishDate, _auto_save = true) {
        this.publishDate = publishDate
        if(_auto_save) this.updateInfo()
    }
    setContent(content, _auto_save = true) {
        this.content = content
        if(_auto_save) this.updateInfo()
    }
    setTime(time, _auto_save = true) {
        this.time = time
        if(_auto_save) this.updateInfo()
    }
    setStar(star, _auto_save = true) {
        this.star = star
        if(_auto_save) this.updateInfo()
    }
    setWallpaper(wallpaper, _auto_save = true) {
        this.wallpaper = wallpaper
        if(_auto_save) this.updateInfo()
    }
    setOfferedBy(offeredBy, _auto_save = true) {
        this.offeredBy = offeredBy
        if(_auto_save) this.updateInfo()
    }
    setAuthor(author, _auto_save = true) {
        this.author = author
        if(_auto_save) this.updateInfo()
    }
    setNVideo(n_video, _auto_save = true) {
        this.n_video = n_video
        if(_auto_save) this.updateInfo()
    }

    setComments(comments, _auto_save = true) {
        this.comments = comments
        if(_auto_save) this.updateInfo()
    } 

    setPriority(priority, _auto_save = true) {
        this.priority = priority
        if(_auto_save) this.updateInfo()
    } 

    /*** A.CRESPI */
    getLessonCheckpoint() { return this.lessonCheckpoint }
    setLessonCheckpoint(lessonCheckpoint, auto_save = true) { 
        this.lessonCheckpoint = lessonCheckpoint
        if(auto_save) this.updateInfo();
    }
    getLastViewedLesson() { return this.lastViewedLesson }
    setLastViewedLesson(lastViewedLesson, auto_save = true) { 
        this.lastViewedLesson = lastViewedLesson
        if(auto_save) this.updateInfo();
    }
    /** */
    
    // Getter
    getTitle() { return this.title }
    getArgument() { return this.argument }
    getPresentationVideo() { return this.presentationVideo }
    getPresentationVideoId() { return this.presentationVideoId }
    getProfessors() { return this.professors }
    getSyllabus() { return this.syllabus }
    getDescription() { return this.description }
    getPublishDate() { return this.publishDate }
    getContent() { return this.content }
    getTime() { return this.time }
    getStar() { return this.star }
    getWallpaper() { return this.wallpaper }
    getOfferedBy() { return this.offeredBy }
    getAuthor() { return this.author }
    getNVideo() { return this.n_video }
    getId() { return this.id }
    getComments() { return this.comments }
    getPriority() { return this.priority }

    addChapter(info = undefined) {
        let _content = this.getContent()
        
        // generating random id
        let newId = "_0"
        while(Object.keys(_content).includes(newId)) newId = "_" + Math.random() * 10
        let title = "Capitolo " + newId
        let lessons = {}
        let position = Object.keys(_content).length + 1

        if(info != undefined) {
            newId = info['slug']
            title = info['title']
            position = info['order']
        }

        _content[newId] = {
            id: newId,
            title: title,
            lessons: lessons,
            position: position
        }
        
        this.setContent(_content)
    }

    getChapter(id) {
        return this.getContent()[id]
    }

    getChapterTitle(chapterId) {
        if(this.getChapter(chapterId) != undefined)
            return this.getChapter(chapterId)['title']
        else return ""
    }


    setChapterTitle(chapterId, title) {
        let _chapterContent = this.getChapter(chapterId)
        _chapterContent['title'] = title
        let _content = this.getContent()
        _content[chapterId] = _chapterContent
        this.setContent(_content)
    }

    getLessonsByChapter(chapterId) {
        if(this.getChapter(chapterId) != undefined)
            return this.getChapter(chapterId)['lessons']
        else return {}
    }

    //*** A.CRESPI */
    getFirstLesson(){
        return Object.values(this.getLessonsByChapter(this.getFirstChapter().id))[0]
    }
    getFirstChapter(){
        return Object.values(this.getContent())[0]
    }
    getNextLessonId(chapter, lesson){
        // let currentChapterLessons = this.getLessonsByChapter(chapterId)
        // let currentPos = this.getLessonPosition(chapterId,lessonId)
        // let orderedLessons = Object
        //     .values(currentChapterLessons)
        //     .sort((a,b)=>a.position - b.position)
        // if(orderedLessons.length === currentPos)
        // this.getLessonPosition()
        if(this.isLastLesson(chapter, lesson)){
            if(this.isLastChapter(chapter)) return undefined
            let nextChapter = this.getNextChapterId(chapter)
            let nextLesson = Object.values(this.getLessonsByChapter(nextChapter))[0].id
            return [nextChapter, nextLesson]
        }else{
            let lessonPos = this.getLessonPosition(chapter,lesson)+1
            let nextLessonId = Object.values(this.getLessonsByChapter(chapter)).find((item=>item.position === lessonPos))?.id
            return [chapter, nextLessonId]
        }

    }

    getNextChapterId(chapter){
        if(this.isLastChapter(chapter)) return undefined
        let chapterPos = this.getChapterPosition(chapter)+1
        return Object.values(this.getContent()).find((item=>item.position === chapterPos))?.id
    }
    getChapterPosition(chapterId){
        return this.getContent()[chapterId]?.position
    }
    isLastLesson(chapterId, lessonId){
        return Object.values(this.getLessonsByChapter(chapterId)).length === this.getLessonPosition(chapterId,lessonId)
    }
    isLastChapter(chapterId){
        return Object.values(this.getContent()).length === this.getChapterPosition(chapterId)
    }
    //*** A.CRESPI */

    addLesson(chapterId, info = undefined) {
        let _chapterContent = this.getChapter(chapterId)
        let newId = "_0"
        while(Object.keys(_chapterContent['lessons']).includes(newId)) newId = "_" + Math.random() * 10
        let title = "Lezione " + newId
        let description = ""
        let video = ""
        let videoId = ""
        let text = ""
        let position = Object.keys(_chapterContent['lessons']).length + 1
        let quiz = {}
        let isFree = false
        let isFinished = false
        // let thumbnail = ""

        if(info != undefined) {
            newId = info['slug']
            title = info['title']
            description = info['description']
            video = info['videoIframe']
            videoId = info['videoId']
            text = info['text']
            position = info['order']
            isFree = info['isFree']
            isFinished = info['isFinished']
            // thumbnail = info['thumbnail']
            let quizList = info['quiz'] ? info['quiz'] : []
            // creating quiz object
            for(let quizItem of quizList) {
                let newQuiz = new QuestionCreationController()
                newQuiz.setOverrideState((() => this.updateInfo()).bind(this))
                newQuiz.question.setType(this.quiz_type)
                newQuiz.question.setId(quizItem['slug'])
                newQuiz.question.setTitle(quizItem['question'])
                let answers = quizItem['answers']
                // setting answers
                for(let answer of answers) {
                    newQuiz.question.addChoice({
                        title: answer['answer'],
                        description: answer['description']
                    }, answer['slug'])
                    if(answer['isCorrect'] != undefined && answer['isCorrect']) {
                        newQuiz.question.addSelectedChoice(answer['slug'])
                    }
                }
                quiz[quizItem['slug']] = newQuiz
            }
        }

        _chapterContent['lessons'][newId] = {
            id: newId,
            title: title,
            description: description,
            video: video,
            videoId: videoId,
            text: text,
            position: position,
            quiz: quiz,
            isFree: isFree,
            isFinished: isFinished,
            // thumbnail: thumbnail
        }


        let _content = this.getContent()
        _content[chapterId] = _chapterContent
        this.setContent(_content)
    }

    removeLesson(chapterId, lessonId) {
        let lessons = this.getLessonsByChapter(chapterId)
        delete lessons[lessonId]
        this.setLessons(chapterId, lessons)
    }

    removeChapter(chapterId) {
        let _chapters = this.getContent()
        delete _chapters[chapterId]
        this.setContent(_chapters)
    }

    setLesson(chapterId, lessonId, lesson) {
        let lessons = this.getLessonsByChapter(chapterId)
        lessons[lessonId] = lesson
        this.setLessons(chapterId, lessons)
    }
    
    setLessons(chapterId, lessons) {
        let chapter = this.getChapter(chapterId)
        chapter['lessons'] = lessons
        let content = this.getContent()
        content[chapterId] = chapter
        this.setContent(content)
    }

    getLesson(chapterId, lessonId) {
        if(this.getLessonsByChapter(chapterId) != undefined)
            return this.getLessonsByChapter(chapterId)[lessonId]
        else return {}
    }

    getLessonTitle(chapterId, lessonId) {
        if(this.getLesson(chapterId, lessonId) != undefined)
            return this.getLesson(chapterId, lessonId)['title']
        else return ""
    }

    getLessonDescription(chapterId, lessonId) {
        if(this.getLesson(chapterId, lessonId) != undefined)
            return this.getLesson(chapterId, lessonId)['description']
        else return ""
    }

    getLessonVideo(chapterId, lessonId) {
        if(this.getLesson(chapterId, lessonId) != undefined)
            return this.getLesson(chapterId, lessonId)['video']
        else return ""
    }

    getLessonVideoId(chapterId, lessonId) {
        if(this.getLesson(chapterId, lessonId) != undefined)
            return this.getLesson(chapterId, lessonId)['videoId']
        else return ""
    }

    getLessonText(chapterId, lessonId) {
        if(this.getLesson(chapterId, lessonId) != undefined)
            return this.getLesson(chapterId, lessonId)['text']
        else return ""
    }

    getLessonPosition(chapterId, lessonId) {
        return this.getLesson(chapterId, lessonId)?.position
    }

    getLessonQuiz(chapterId, lessonId) {
        if(this.getLesson(chapterId, lessonId) != undefined)
            return this.getLesson(chapterId, lessonId)['quiz']
        else return ""
    }

    getSpecificQuiz(chapterId, lessonId, quizId) {
        if(this.getLessonQuiz(chapterId, lessonId) != undefined)
            return this.getLessonQuiz(chapterId, lessonId)[quizId]
        else return ""
    }

    setLessonContent(chapterId, lessonId, key, value) {
        let chapter = this.getChapter(chapterId)
        let chapterLessons = this.getLessonsByChapter(chapterId)
        let lesson = this.getLesson(chapterId, lessonId)
        let content = this.getContent()
        lesson[key] = value
        chapterLessons[lessonId] = lesson
        chapter['lessons'] = chapterLessons
        content[chapterId] = chapter
        this.setContent(content)
    }
    
    setLessonTitle(chapterId, lessonId, title) {
        this.setLessonContent(chapterId, lessonId, 'title', title)
    }

    setLessonDescription(chapterId, lessonId, description) {
        this.setLessonContent(chapterId, lessonId, 'description', description)
    }

    setLessonVideo(chapterId, lessonId, video) {
        this.setLessonContent(chapterId, lessonId, 'video', video)
    }

    setLessonVideoId(chapterId, lessonId, videoId) {
        this.setLessonContent(chapterId, lessonId, 'videoId', videoId)
    }

    setLessonText(chapterId, lessonId, text) {
        this.setLessonContent(chapterId, lessonId, 'text', text)
    }
  
    setQuiz(chapterId, lessonId, quiz) {        // OBJECT OF QUIZ
        let lesson = this.getLesson(chapterId, lessonId)
        lesson['quiz'] = quiz
        this.setLesson(chapterId, lessonId, lesson)
    }
    
    getQuiz(chapterId, lessonId) {
        return this.getLesson(chapterId, lessonId)['quiz']
    }

    addQuiz(chapterId, lessonId, quizContent = undefined) {
        let newQuiz = new QuestionCreationController()
        newQuiz.setOverrideState((() => this.updateInfo()).bind(this))
        if(quizContent == undefined)
            newQuiz.load({question: {title: "Nuova domanda", acceptedChoices : undefined}})
        else
            newQuiz.load({question: quizContent})

        let chapter = this.getChapter(chapterId)
        let chapterLessons = this.getLessonsByChapter(chapterId)
        let lesson = this.getLesson(chapterId, lessonId)
        let content = this.getContent()
        
        let id = "_0"
        if(quizContent != undefined) id = quizContent[id]
        else while(Object.keys(lesson['quiz']).includes(id)) id = "_" + Math.random() * 10

        lesson['quiz'][id] = newQuiz 
        chapterLessons[lessonId] = lesson
        chapter['lessons'] = chapterLessons
        content[chapterId] = chapter
        this.setContent(content)
    }

    removeQuiz(chapterId, lessonId, quizId) {
        let quiz = this.getQuiz(chapterId, lessonId)
        delete quiz[quizId]
        this.setQuiz(chapterId, lessonId, quiz)
    }


    getQuizById(chapterId, lessonId, quizId) {
        return this.getQuiz(chapterId, lessonId)[quizId]
    }

    loadComments(n = 10) {
        let genericComment = {
            title : "Titolo bello",
            content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
        }
        let list = []
        for(let i = 0; i < n; i++) list.push(genericComment)
        this.setComments(list)
    }

    exportInfo() {
        let content = JSON.parse(JSON.stringify(this.getContent()))

        return {
            id: this.getId(),
            title : this.getTitle(),
            argument : this.getArgument(),
            wallpaper : this.getWallpaper(),
            description : this.getDescription(),
            presentationVideo : this.getPresentationVideo(),
            presentationVideoId : this.getPresentationVideoId(),
            professors : this.getProfessors(),
            offeredBy : this.getOfferedBy(),
            syllabus : this.getSyllabus(),
            publishDate : this.getPublishDate(),
            content : content,
            time : this.getTime(),
            star : this.getStar(),
            author : this.getAuthor(),
            n_video : this.getNVideo(),
            comments : this.getComments(),
            priority : this.getPriority()
        }
    }
    
}

export default CourseController