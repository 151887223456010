
import Question from "../models/question"
import { api_url } from "../App"
import $ from "jquery"
import { beforeSendCallback } from "../utils"

class QuestionController extends Question {
    constructor() {
        super()
        this.selectedChoices = []       // contains selected choices ID
        this.state = undefined
        this.overrideUpdateInfo = undefined
    }

    loadInfo({id = "", title = "", image = "", choices = {}, acceptedChoices = [], type = undefined, completed = false}) {
        this.id = id
        this.title = title
        this.image = image
        this.choices = choices
        this.acceptedChoices = acceptedChoices
        this.type = type
        this.completed = completed
    }

    load({id, title, image, choices, acceptedChoices = 1, selectedChoices = [], type, completed = false}) {
        this.loadInfo({id, title, image, choices, acceptedChoices, type, completed})
        this.selectedChoices = selectedChoices
        this.updateInfo()
    }

    async loadById(id, quizType = "academy") {

        // checking quizType validity 
        let acceptedQuizType = ["academy", "course", "school"]
        if(!acceptedQuizType.includes(quizType)) {
            console.warn("quizType is not valid - " + quizType)
            throw Error("Internal error")
        }

        // TODO: (if necessary) settare parametri
        let quizInfo = {}
        let requestLink = ""

        if(quizType == "course") {
            // course quiz
            requestLink = "course/quiz/" + id
        } else if(quizType == "academy"){
            // nromal quiz
            requestLink = "/quiz/" + id
        } else if(quizType == "school") {
            // school quiz
            requestLink = "school/quiz/" + id
        }
        
        await $.ajax({
            type: "GET",
            url: api_url + requestLink,
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
            success: (data) => {
                quizInfo = data['quiz']
            }
        })

        return quizInfo
    }

    async sendUserAnswer(id) {
        let info = {
            isCorrect : false,
            xp: undefined
        }

        let requestLink = ""
        if(this.getType() == "course") {
            requestLink = "/course/lesson/answer/" + id + "/user_answer"
        } else if(this.getType() == "academy") {
            requestLink = "/quiz/answer/" + id +"/user_answer"
        }

        if(requestLink != "") {
            await $.ajax({
                type: "POST",
                url: api_url + requestLink,
                accepts: "json",
                contentType: "json",
                beforeSend: beforeSendCallback,
                success: (data) => {
                    info['isCorrect'] = data['message']['isCorrect']
                    info['xp'] = data['xp']
                }
            })
        } else info = undefined

        return info
    }

    setOverrideUpdateInfo(callback) {
        this.overrideUpdateInfo = callback
    }

    updateInfo() {
        if(this.state != undefined) {
            this.state(new QuestionController(this.question, this.selectedChoices, this.state, this.overrideUpdateInfo))
        }
        else if(this.overrideUpdateInfo != undefined) {
            this.overrideUpdateInfo()
        }
    }

    isCompleted(){ return this.completed}
    getId() {return this.id}
    getTitle() {return this.title}
    getChoices() {return this.choices}
    getImage() { return this.image }
    getAcceptedChoices() {return this.acceptedChoices}
    getSelectedChoices() {return this.selectedChoices}
    getType() {return this.type}

    complete(_auto_save = true){
        this.completed = true
        if(_auto_save) this.updateInfo()
    }

    setCompleted(completed, _auto_save = true) {
        this.completed = completed
        if(_auto_save) this.updateInfo()
    }

    setId(id, _auto_save = true) {
        this.id = id
        if(_auto_save) this.updateInfo()
    }

    setTitle(title, _auto_save = true) {
        this.title = title
        if(_auto_save) this.updateInfo()
    }

    setChoices(choices, _auto_save = true) {
        this.choices = choices
        if(_auto_save) this.updateInfo()
    }

    setImage(image, _auto_save = true) {
        this.image = image
        if(_auto_save) this.updateInfo()
    }

    setAcceptedChoices(acceptedChoices, _auto_save = true) {
        this.acceptedChoices = acceptedChoices
        if(_auto_save) this.updateInfo()
    }
    setSelectedChoices(selectedChoices, _auto_save = true) {
        this.selectedChoices = selectedChoices
        if(_auto_save) this.updateInfo()
    }
    setType(type, _auto_save = true) {
        this.type = type
        if(_auto_save) this.updateInfo()
    }

    setState(state) {
        this.state = state
    }

    addChoice(choice, id = undefined) {
        /**
         * choiche = {
         *  title: str,
         *  description: str,
         * }
         */
        let newId = ""

        if(id == undefined){
            newId = "_0"
            while(Object.keys(this.getChoices()).includes(newId)) newId = "_" + Math.random() * 10
        } else {
            newId = id
        }

        let _newChoices = this.getChoices()
        _newChoices[newId] = choice
        this.updateInfo()
    }

    addSelectedChoice(id, _auto_save = true) {
        // if(id in this.getChoices()) {
        if(id != undefined) {
            let _temp = this.getSelectedChoices()
            if(this.getAcceptedChoices())
                if(this.getAcceptedChoices() !== -1 && this.getSelectedChoices().length + 1 > this.getAcceptedChoices()) {
                    _temp = _temp.slice(1)
                }
            
            if(!_temp.includes(id)) _temp.push(id)
            
            this.setSelectedChoices(_temp, _auto_save)
        }
        if(_auto_save) this.updateInfo()
    }

    removeSelectedChoice(id, _auto_save = true) {
        let index = this.selectedChoices.indexOf(id)
        if(index > -1) this.selectedChoices.splice(index, 1)
        if(_auto_save) this.updateInfo()
    }

    isChoiceSelected(id) {return this.getSelectedChoices().includes(id)}

    exportQuestion() {
        return {
            title: this.getTitle(),
            image: this.getImage(),
            choices: this.getChoices(),
            acceptedChoices: this.getAcceptedChoices(),
            selectedChoices: this.getSelectedChoices()
        }
    }

    areChoicesSelected() {
        return (this.getAcceptedChoices() === -1 && this.getSelectedChoices().length > 0) || this.getSelectedChoices().length === this.getAcceptedChoices()
    }
}

export default QuestionController
