
class NewsModel{
    constructor() {
        this.id = ""
        this.title = ""
        this.description = ""
        this.publishDate = ""
        this.author = ""
        this.category = {}
        this.content = ""
        this.wallpaper = ""
        this.socialPage = ""
        this.comments = []
        this.comment_page_index = 1
        this.isSaved = false
        this.isLiked = false
    }
}

export default NewsModel