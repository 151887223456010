import React, { useState, useEffect, useRef } from 'react'
import RichTextEditor from 'react-rte';

import "./style.css"

function MarkupEditor(props) {
    let initialValue = props.content
    let setContent = props.setContent
    const loadInitialValue = useRef(true)       // stoplight
    const [currentValue, setCurrentValue] = useState(RichTextEditor.createEmptyValue())
    const contentWithoutSpace = useRef("")

    const toolbarConfig = {
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS', 'IMAGE_BUTTON'],
        INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        // {label: 'Underline', style: 'UNDERLINE'},
        {label: "Strikethrough", style: "STRIKETHROUGH"},
        ],
        BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
        ]
    }

    // on mobile app tag like "** ciao**" aren't supported
    function removeSpaces(string) {
        let triggerChars = ['*', "_", "~"]
        let errorOccured = true

        while(errorOccured) {
            errorOccured = false
            let index = -1
            let lastChar = ""
            let checkErrorOnClose = false
            let checkErrorOnOpen = ""
            let openTags = []

            for(let char of string) {
                index++
                if(triggerChars.includes(char)) {
                    if(lastChar == char || char == "_") {
                        // open/close tags
                        if(openTags.includes(char)) {
                            // close tag
                            let position = openTags.indexOf(char)
                            if(position != -1) openTags.splice(position, 1)
                            if(checkErrorOnClose) {
                                // error on close tag
                                errorOccured = true
                                if(char == "_") {
                                    string = string.replaceAt(index - 1, "")
                                    string = string.slice(0, index) + " " + string.slice(index)
                                } else {
                                    string = string.replaceAt(index - 2, "")
                                    string = string.slice(0, index) + " " + string.slice(index)
                                }
                                checkErrorOnClose = false
                            }
                        } else {
                            // open tag
                            openTags.push(char)
                            checkErrorOnOpen = char
                        }
                    }
                } else {
                    if(checkErrorOnOpen != "" && char == " ") {
                        // error on open tag
                        errorOccured = true
                        if(checkErrorOnOpen == "_") {
                            string = string.slice(0, index - 1) + " " + string.slice(index - 1);
                            string = string.replaceAt(index + 1, "")
                        } else {
                            string = string.slice(0, index - 2) + " " + string.slice(index - 2);
                            string = string.replaceAt(index + 1, "")
                        }
                    }
    
                    checkErrorOnOpen = ""
                    checkErrorOnClose = false
    
                    if(char == " ") checkErrorOnClose = true
                }
                lastChar = char
            }
        }

        return string
    }

    function onChangeHandler(value) {
        setCurrentValue(value)
    }

    useEffect(() => {
        loadInitialValue.current = true
        if(initialValue != contentWithoutSpace.current)
            setCurrentValue(RichTextEditor.createValueFromString(initialValue, 'markdown'))
    }, [initialValue]) 
    
    useEffect(() => {
        if(setContent != undefined && !loadInitialValue.current) {
            contentWithoutSpace.current = removeSpaces("" + currentValue.toString('markdown'))
            setContent(removeSpaces("" + currentValue.toString('markdown')))
        } else {
            loadInitialValue.current = false
        }
    }, [currentValue])

    useEffect(() => {
        // useful function
        String.prototype.replaceAt = function(index, replacement) {
            let length = Math.max(replacement.length, 1)
            return this.substring(0, index) + replacement + this.substring(index + length);
        }
    }, [])
    

    return (
        <RichTextEditor
            className="block no-border"
            editorClassName={"editor"}
            toolbarClassName={"toolbar"}
            toolbarConfig={toolbarConfig}
            value={currentValue}
            onChange={onChangeHandler}
        />
    )
}

export default MarkupEditor
