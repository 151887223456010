import $ from 'jquery'
import React, {Suspense} from 'react'
import { useNavigate } from 'react-router-dom'
import { default as internal_routes } from "./routes"



function SectionNavbar(props){
    let section = props.section
    let user = props.user
    let loadOnRoleSection = props.loadOnRoleSection    //temp
    let navigate = useNavigate()

    // sections name
    const INFO_SECTION = ""
    const PAYMENTS = "payments"
    const NEWS_SECTION = "news"
    const ACCADEMY_SECTION = "academy"
    const COURSE_SECTION = "course"
    const WEBINAR_SECTION = "webinar"
    const SCHOOL_SECTION = "school"
    const STATISTIC_SECTION = "statistiche"
    const TAG_FIELD = "tag"
    const QUESTION_SECTION = "question"


    return(
        <>
            <p 
                className={section == INFO_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                onClick={() => navigate(internal_routes.info.path)}
            >
                Informazioni
            </p>
            <p 
                className={section == PAYMENTS ? "menu_item bounce selected" : "menu_item bounce"}
                onClick={() => navigate(internal_routes.payments.path)}
            >
                Abbonamento
            </p>
            <p 
                className={section == NEWS_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                onClick={() => navigate(internal_routes.news.path)}
            >
                News
            </p>
            <p 
                className={section == ACCADEMY_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                onClick={() => navigate(internal_routes.academy.path)}
            >
                Academy
            </p>
            {/* FOR DEVELOPMENT POPOUSES */}
            {/* CRITICO ############################################################################# */}
            {
                loadOnRoleSection && user && user.canI("create_course") ?
                <p 
                    className={section == COURSE_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                    onClick={() => navigate(internal_routes.course.path)}
                >
                    Corsi
                </p> :
                ""
            } 
            {
                loadOnRoleSection && user && user.canI("create_webinar") ?
                <p 
                    className={section == WEBINAR_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                    onClick={() => navigate(internal_routes.webinar.path)}
                >
                    Webinar
                </p> :
                ""
            }
            {
                loadOnRoleSection && user && user.canI("create_school") ?
                <p 
                    className={section == SCHOOL_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                    onClick={() => navigate(internal_routes.school.path)}
                >
                    Scuola
                </p> :
                ""
            }
            {
                loadOnRoleSection && user && user.canI("manage_tag") ?
                <p 
                    className={section == TAG_FIELD ? "menu_item bounce selected" : "menu_item bounce"}
                    onClick={() => navigate(internal_routes.tag.path)}
                >
                    Tag & Field
                </p> :
                ""
            }
            {/* <p 
                className={section == STATISTIC_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                onClick={() => navigate(internal_routes.stat.path)}
            >
                Statistiche
            </p> */}
            <p 
                className={section == QUESTION_SECTION ? "menu_item bounce selected" : "menu_item bounce"}
                onClick={() => navigate(internal_routes.question.path)}
            >
                Domande
            </p>
        </>
    )
}
export default SectionNavbar;