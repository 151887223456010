import { Container } from '@material-ui/core'
import React from 'react'
import SingleNews from '../dashboard/single_news'

function NewsPage(props) {
  let windowInfo = props.windowInfo

  return (
    <Container className="news_page"><SingleNews /></Container>
  )
}

export default NewsPage