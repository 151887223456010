import CommentModel from "../models/comment";

class CommentController extends CommentModel {
    constructor() {
        super()
        this.state = undefined
        this.overrideState = undefined
    }

    load({id = "", title = "", author = "", date = "", content = "", author_username = ""}) {
        this.id = id
        this.title = title
        this.author = author
        this.date = date
        this.content = content
        this.author_username = author_username
    }

    updateInfo() {
        if(this.state != undefined) {
            let newIntstance = new CommentController()
            Object.assign(newIntstance, this)
            this.state(newIntstance)
        }
        else if(this.overrideState != undefined) this.overrideState()
    }

    setState(state) {
        this.state = state
    }

    setOverrideState(overrideState) {
        this.overrideState = overrideState
    }

    getId() { return this.id }
    getTitle() { return this.title }
    getAuthor() { return this.author }
    getDate() { return this.date }
    getContent() { return this.content }
    getAuthorUsername() { return this.author_username }

    setId(id, _auto_save) {
        this.id = id
        if(_auto_save) this.updateInfo()
    }

    setTitle(title, _auto_save = true) {
        this.title = title
        if(_auto_save) this.updateInfo()
    }
    
    setAuthor(author, _auto_save = true) {
        this.author = author
        if(_auto_save) this.updateInfo()
    }

    setDate(date, _auto_save = true) {
        this.date = date
        if(_auto_save) this.updateInfo()
    }

    setContent(content, _auto_save = true) {
        this.content = content
        if(_auto_save) this.updateInfo()
    }

    setAuthorUsername(author_username, _auto_save = true) {
        this.author_username = author_username
        if(_auto_save) this.updateInfo()
    }

    exportInfo() {
        return {
            id : this.getId(),
            title : this.getTitle(),
            content : this.getContent(),
            date : this.getDate(),
            author : this.getAuthor(),
            author_username : this.getAuthorUsername()
        }
    }
}

export default CommentController