import React, {useState, useEffect} from 'react'
import "./style.css"

function TestPage(props) {    
    const [done, setDone] = useState(false)
    const [value, setValue] = useState("")

    function doRequest(url) {
        if(url != "") {
            setDone(true)
            window.location.href = url
            setTimeout(() => {
                setDone(false)
            }, 1000)
        }
    }
    

    return <div id="test_page" className="centered">
        <input
        placeholder="Inserisci il link"
        value={value}
        onChange={(e) => setValue(e.target.value)}/>
        <button onClick={() => doRequest(value)}>Invia</button>
        <br/>
        <h6>Shortcut</h6>
        <button onClick={() => doRequest("finanz://")}>finanz://</button>
        <button onClick={() => doRequest("finanz://finanz")}>finanz://finanz</button>
        {
            done ? 
            <h1>Fatto</h1> :
            ""
        }
    </div>
}

export default TestPage
