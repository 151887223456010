import { lazy, Suspense} from "react"
// import AccademySection from "./sections/accademy_section"
// import CoursesSection from "./sections/courses_section"
// import InfoSection from "./sections/info_section"
// import NewsSection from "./sections/news_section"
// import Payments from "./sections/payments"
// import QuestionSection from "./sections/question_section"
// import SchoolSection from "./sections/school_section"
// import TagField from "./sections/tag_field"
// import WebinarSection from "./sections/webinar_section"
// import StatisticSection from "./sections/statistic"

const AccademySection = lazy(()=> import("./sections/accademy_section"))
const CoursesSection = lazy(()=> import("./sections/courses_section"))
const InfoSection = lazy(()=> import("./sections/info_section"))
const NewsSection = lazy(()=> import("./sections/news_section"))
const Payments = lazy(()=> import("./sections/payments"))
const QuestionSection = lazy(()=> import("./sections/question_section"))
const SchoolSection = lazy(()=> import("./sections/school_section"))
const TagField = lazy(()=> import("./sections/tag_field"))
const WebinarSection = lazy(()=> import("./sections/webinar_section"))


const base_url = "/dashboard/account/"

let routes = {
  info: {
    path: base_url + "",
    url: "",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <InfoSection {...props} /> </Suspense>
  },
  payments: {
    path: base_url + "payments",
    url: "payments",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <Payments {...props} /> </Suspense>
  },
  news: {
    path: base_url + "news",
    url: "news",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <NewsSection {...props} /> </Suspense>
  },
  academy: {
    path: base_url + "academy",
    url: "academy",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <AccademySection {...props} /> </Suspense>
  },
  course: {
    path: base_url + "course",
    url: "course",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <CoursesSection {...props} /> </Suspense>
  },
  webinar: {
    path: base_url + "webinar",
    url: "webinar",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <WebinarSection {...props} /> </Suspense>
  },
  school: {
    path: base_url + "school",
    url: "school",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <SchoolSection {...props} /> </Suspense>
  },
  tag: {
    path: base_url + "tag",
    url: "tag",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <TagField {...props} /> </Suspense>
  },
  // statistic: {
  //   path: base_url + "statistics",
  //   url: "statistics",
  //   component: (props) => <StatisticSection {...props} />
  // },
  question: {
    path: base_url + "question",
    url: "question",
    component: (props) => <Suspense fallback={<div>Loading...</div>}> <QuestionSection {...props} /> </Suspense>
  },
  
}

export default routes