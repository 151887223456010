class Module {
    constructor() {
        this.id = ""
        this.author = ""
        this.socialPage = ""
        this.argument = ""
        this.difficultyLevel = ""
        this.position = 1
        this.n_modules = 0
        this.time = 30
        this.wallpaper = ""
        this.title = ""
        this.description = ""
        this.modules = {}
        this.publishDate = ""
        this.completedNotes = []
        this.quiz_type = "academy"
    }
}

export default Module