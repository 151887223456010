import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {Row, Col} from "react-bootstrap"
import routes from '../routes';
import "./style.css"
import { Accordion, AccordionDetails } from '@mui/material';
import { AccordionSummary } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SectionNavbar from "./section_navbar"
import { default as internal_routes } from "./routes"
import CustomRouter from '../../../../components/custom_router';
import { Route, useParams } from 'react-router';

function AccountHome(props) {
    let navigate = useNavigate()
    let params = useParams()
    let section = params['*']
    let user = props.user
    let windowInfo = props.windowInfo
    const [openMobileMenu, setOpenMobileMenu] = useState(false)


    return <div className="account_page">
        {
            !windowInfo.mobileMode ?
            <>
                <br/>
                <br/>
            </> :
            ""
        }
        <Row>
            <Col md="3">
                <div className="sidebar">
                    {
                        !windowInfo.mobileMode ?
                        <>
                            <h3>Menu</h3>
                            <SectionNavbar loadOnRoleSection={true} section={section} user = {user} />
                        </> 
                        
                        : 
                        <Accordion
                        expanded={openMobileMenu}
                        className="mobile_menu">
                            <AccordionSummary
                            onClick={() => setOpenMobileMenu(!openMobileMenu)}
                            expandIcon={<KeyboardArrowDownIcon className="orange_icon" />}>
                                <h3 className="m-0">Menu</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div onClick={() => setOpenMobileMenu(false)}>
                                    <SectionNavbar loadOnRoleSection={true} section={section} user = {user} />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    }
                    
                </div>
            </Col>
            <Col md="7" className="content">
                <CustomRouter>
                    {Object.values(internal_routes).map(route => (
                        <Route path={route.url} element={route.component({routes: routes, user: user, windowInfo: windowInfo})}/>
                    ))}
                </CustomRouter>
            </Col>
        </Row>
        <br/>
    </div>
}

function StatisticSection(props) {
    return <div id="info_section">
        STATISTIC_SECTION
    </div>
}



export default AccountHome