
import Webinar from "../media/img/webinar.jpeg"

let webinarData = {
    id : "1",
    title : 'Lorem ipsum dolor sit amet',
    description : 'Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione.',
    date : '10/02/1932',
    authors : ['Piero Angela', 'Gino il Grande'],
    wallpaper : Webinar,
}

export default webinarData