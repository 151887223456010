import Cookies from 'js-cookie'

class AuthJWT{

    static useHTTPOnly = false

    static getAccessToken(){
        return (!AuthJWT.useHTTPOnly && Cookies.get()?.accessToken) ? Cookies.get("accessToken") : undefined;
    }

    static setAccessToken(newAccessToken){
        //need to check validity of string as a JWT
        if(!AuthJWT.useHTTPOnly)
            Cookies.set("accessToken", newAccessToken, { expires: 1 })
    }

    static getRefreshToken(){
        return  (!AuthJWT.useHTTPOnly && Cookies.get()?.refreshToken) ? Cookies.get("refreshToken") : undefined;
    }

    static setRefreshToken(newRefreshToken){
        //need to check validity of string as a JWT
        if(!AuthJWT.useHTTPOnly)
            Cookies.set("refreshToken", newRefreshToken, { expires: 1 })
    }
}

export default AuthJWT