class CourseModel {
    constructor() {
        this.id = ""
        this.title = ""
        this.argument = ""
        this.wallpaper = ""
        this.description = ""
        this.presentationVideo = ""
        this.presentationVideoId = ""
        this.professors = []
        this.offeredBy = [] //
        this.syllabus = ""
        this.publishDate = ""
        this.content = {}
        this.time = ""
        this.star = ""
        this.author = "" //
        this.n_video = 0 //
        this.comments = []
        this.quiz_type = "course"
        this.priority = 1
    }
}

export default CourseModel