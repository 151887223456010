import React, {useState, useEffect} from 'react'
import {Container, Row, Col} from "react-bootstrap"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fade from '@mui/material/Fade';

import Logo from "../../media/img/logo.png"
import MainImageHome from "../../media/img/static_home/main_image.png"
import NewsPhone from "../../media/img/static_home/news_phone.png"
import CoursePhone from "../../media/img/static_home/course_phone.png"
import AcademyPhone from "../../media/img/static_home/academy_phone.png"

import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HashLink } from 'react-router-hash-link';
import CookieConsent from "react-cookie-consent";
import routes from './routes'; 

import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PsychologyIcon from '@mui/icons-material/Psychology';

import $ from "jquery"

import Flex from "../../media/img/static_home/flex.png"
import Ai from "../../media/img/static_home/ai.png"
import Arrow from "../../media/img/static_home/arrow.png"
import { Navigate, useNavigate } from 'react-router-dom';
import { api_url } from '../../App';
import { Link } from 'react-router-dom';

function Home(props) {
  const numCourses = 12;
  let windowInfo = props.windowInfo
  let mobileMode = windowInfo['mobileMode']
  
  const [coursesPreview, setCoursesPreview] = useState([])

  let nav = useNavigate()

  useEffect(()=>{
    $.get({
      url:api_url + "/courses/free/" + numCourses,
      accepts:"application/json"
    }).then((data)=>{
      console.log(data.courses);
      setCoursesPreview(data.courses)
    })
  }, [])

  return (
    <div>
      {/* NAVBAR */}
      <Container>
        {/* MAIN SECTION */}
        <Row className="main_section">
          <Col md="6" className="centered" style={{ mixBlendMode: "plus-lighter"}}>
            <Fade in={true} style={{ transitionDelay: `0.3s` }} timeout={1000}>
              <div>
                <h1 className="main_title">IMPARA</h1>
                <h1 className="main_title">LA FINANZA</h1>
                <h1 className="main_title">DIVERTENDOTI</h1>
              </div>
            </Fade>
            <Fade style={{ transitionDelay: `0.3s` }} in={true} timeout={1000}>
              <div>
                <Col md="9">
                  <p className="description">Il primo metodo gratuito che ti insegna tutto sul mondo finanziario in soli <span className="bold">5 minuti</span> al giorno!</p>
                </Col>
              </div>
            </Fade>
            <Fade style={{ transitionDelay: `0.3s` }} in={true} timeout={1000}>
              <div className="display_inline">
              {
                mobileMode
                ? <a href="https://onelink.to/finanz-app"><button className="bounce button">Inizia gratis</button></a>
                : <HashLink to="/dashboard"><button className="bounce button">Inizia gratis</button></HashLink>
              }
                <HashLink to={routes.home.path + "#discover"}><button className="bounce button">Scopri di più</button></HashLink>
              </div>
            </Fade>
          </Col>
          <Col md="6" className="centered">
            <Fade style={{ transitionDelay: `0.3s` }} in={true} timeout={1000}>
              <img src={MainImageHome} className="img-fluid"/>
            </Fade>
          </Col>
        </Row>

        <br/>
        <br/>
        <br/>

        <a id="discover" />
        <br/>
        {/* PERCHE' AMERAI FINANZ */}
        <div className="love ball">
          <div className="animate">
            <h6 className="sub_title">PERCHE' AMERAI FINANZ</h6>
            <h1 className="title">Il percorso didattico <span className="highlight-text">più efficace</span> mai creato</h1>
          </div>
          <Row>
            <Col md="1"></Col>
            <Col md="5">
              <div className="love_card animate">
                <span className="number mx-auto">01</span>
                <div className="content block">
                  <div className="icon_container"><TaskAltIcon className="icon"/></div>
                  <h6 className="">PERFORMANTE</h6>
                  <p className="description">Lezioni brevi ed intuitive per ottenere risultati e migliorare velocemente.</p>
                </div>
              </div>
            </Col>
            <Col md="5">
              <div className="love_card mt-5 animate">
                <span className="number mx-auto">02</span>
                <div className="content block">
                  <div className="icon_container"><PsychologyIcon className="icon"/></div>
                  <h6 className="">STIMOLANTE</h6>
                  <p className="description">Approccio dinamico e coinvolgente che mantiene al massimo la tua motivazione.</p>
                </div>
              </div>
            </Col>
            <Col md="1"></Col>
          </Row>
          <Row>
            <Col md="1"></Col>
            <Col md="5">
              <div className="love_card animate">
                <span className="number mx-auto">03</span>
                <div className="content block">
                  <div className="icon_container"><SportsEsportsIcon className="icon"/></div>
                  <h6 className="">DIVERTENTE</h6>
                  <p className="description"> Sviluppi abilità e competenze pratiche, senza mai annoiarti.</p>
                </div>
              </div>
            </Col>
            <Col md="5">
              <div className="love_card animate">
                <span className="number mx-auto">04</span>
                <div className="content block">
                  <div className="icon_container"><DeveloperBoardIcon className="icon"/></div>
                  <h6 className="">ALGORITMI INTELLIGENTI</h6>
                  <p className="description">Percorsi personalizzati che si adattano al tuo livello ed abitudini di studio.</p>
                </div>
              </div>
            </Col>
            <Col md="1"></Col>
          </Row>
        </div>

        <br/>
        <br/>
        <br/>

        {/* COSA OFFRIAMO */}
        <div className="offer ball">
          <h6 className="sub_title animate">COSA TROVERAI DENTRO FINANZ</h6>
          <h1 className="title animate">Un'esperienza che offre <span className="highlight-text">opportunità</span> senza limiti</h1>
          <br />
          <Col md="11" id="offer-sections" className="sections block width-100 mx-auto animate">
            <div className="offer-grid">
              <div className="text-view rightside">
                <h4 className="text-view-title highlight-text">ACADEMY</h4>
                <p className="text-view-description">200+ lezioni gratuite per guidarti in modo efficace e divertente verso i tuoi traguardi più ambiziosi.</p>
              </div>
              <div className="text-view image-viewport">
                <img src={AcademyPhone} alt="Not found."/>
                <img src={CoursePhone} alt="Not found."/>
                <img src={NewsPhone} alt="Not found."/>
              </div>
              <div className="text-view leftside">
                <h4 className="text-view-title highlight-text">BREAKING NEWS</h4>
                <p className="text-view-description">Le notizie più importanti selezionate in tempo reale dalla nostra redazione ti terranno sempre aggiornato.</p>
              </div>
              <div className="text-view rightside">
                <h4 className="text-view-title highlight-text">FINANZ PLUS</h4>
                <p className="text-view-description">Videocorsi tenuti dai migliori esperti del settore per ottimizzare la tua formazione ed accelerare i tuoi progressi.</p>
              </div>
              <div className="text-view leftside">
                <h4 className="text-view-title highlight-text">ARTIFICIAL INTELLIGENCE</h4>
                <p className="text-view-description">Il nostro algoritmo intelligente analizza i tuoi punti forza, competenze ed tue esigenze, sviluppando percorsi di studio personalizzati, adatti al tuo livello di partenza e agli obiettivi che vuoi raggiungere.</p>
              </div>
            </div>
          </Col>

        </div>


        {/* <OfferSection /> */}

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        {/* CORSI E CERTIFICATI */}
        <div className="courses ball">
          <div className="animate" style={{ mixBlendMode: "plus-lighter"}}>
            {
              mobileMode ?
                <h6 className="sub_title">ACCELLERA I <br/>TUOI PROGRESSI<br/>CON FINANZ PLUS</h6> :
                <h6 className="sub_title">ACCELLERA I TUOI PROGRESSI CON FINANZ PLUS</h6>
            }
            <h1 className="title">Contenuti originali accessibili a tutti</h1>
            {/* <h1 className="title">La didattica performante che ti aiuta a raggiungere prima i tuoi obbiettivi</h1>
            <Col md="10">
              <p className="description animate"><b>Finanz plus</b> è un'esperienza unica e completa. Creata con esperti per offrire video corsi efficaci e coinvolgenti. Farai parte di una community esclusiva e imparerai dai migliori per raggiungere prima i tuoi obbiettivi.</p>
            </Col> */}
          </div>
          <br/>
          
          <Fade in={true}>
            <Row className="animate">
              <Container fluid>
                <Row className='mb-5'>
                  {
                    coursesPreview && coursesPreview?.map(course =>
                      <Col 
                        key={"course-preview-"+course.slug}
                        className='course-preview mb-4'
                        md="4" sm="12" xs="12"
                        onClick={()=>nav("/dashboard/courses/" + course.slug)}
                      >
                        <div className="course-info-box">
                          <h4 className='course-title'>{course.title}</h4>
                          <h5 className='description'>Visita <ArrowForwardIosIcon/></h5>
                        </div>
                        <img src={course.coverImageLink} className="course-img" alt="not found."/> 
                      </Col>
                    )
                  }
                </Row>
              </Container>
            </Row>
          </Fade> 

          
          <br/>
          <div className="centered animate">
            <Link to="/dashboard/account/payments" replace className="button">Entra in Finanz Plus</Link>
          </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </Container>

      {/* ABBONAMENTO */}
      <a id="subscription"></a>
      <div className="subscription">
        <Container>
          <div className="animate">
            <h6 className="sub_title">Mostra i tuoi risultati e rafforza il tuo CV</h6>
            <h1 className="title">Rafforza il tuo CV e mostra i tuoi <span className="highlight-text">risultati</span></h1>
            {/* <p className="description">Entrando nell'area abbonati accedi ad un esclusivo percorso di Formazione con più di 25 video corsi realizzati da veri esperti e professionisti del settore che ti permettono di ottenere il nostro attestato da allegare al tuo CV o caricare su Linkedin!</p> */}
          </div>
          <br/>
          <Row className="animate">
            <Col md="4" className="mb-3">
              <div className="block bounce prices">
                <h1 className="text-center">Gratuito</h1>
                <p className="description">
                  <h6 className="mini_title">Academy</h6>
                  Accesso illimitato all'area
                </p>
                <p className="description">
                  <h6 className="mini_title">News</h6>
                  Trend del momento e novità finanziarie
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">Video corsi</h6>
                  Accedi senza limiti a più di 25 corsi didattici online
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">Lezioni esclusive</h6>
                  Segui più di 500 lezioni dove e quando vuoi
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">Formazione pratica</h6>
                  Acquisisci competenze operative da applicare sul campo
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">Webinar settimanali</h6>
                  Partecipa a incontri ed Eventi Live tenuti da professionisti
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">Community</h6>
                  Fai domande ai docenti e ti confronti con gli esperti del settore
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">Test e Quiz</h6>
                  Esercitati e verifica i tuoi progressi per conoscere il tuo grado di preparazione
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">CertiFinanZ</h6>
                  Convalida i tuoi traguardi e conquista il mondo del lavoro con il nostro attestato
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">2 Mesi Gratuiti</h6>
                  Ottieni 60 giorni di apprendimento illimitato in regalo
                </p>
                <h1 className="text-center title">&nbsp;</h1>
                <br/>
                <div className="centered">
                  <Link to="/dashboard" replace className="button bounce button m-0">Inizia Subito</Link>
                </div>
              </div>
            </Col>
            <Col md="4"  className="mb-3">
              <div className="block prices bounce">
                <h1 className="text-center">Mensile</h1>
                <p className="description">
                  <h6 className="mini_title">Academy</h6>
                  Accesso illimitato all'area
                </p>
                <p className="description">
                  <h6 className="mini_title">News</h6>
                  Trend del momento e novità finanziarie
                </p>
                <p className="description">
                  <h6 className="mini_title">Video corsi</h6>
                  Accedi senza limiti a più di 25 corsi didattici online
                </p>
                <p className="description">
                  <h6 className="mini_title">Lezioni esclusive</h6>
                  Segui più di 500 lezioni dove e quando vuoi
                </p>
                <p className="description">
                  <h6 className="mini_title">Formazione pratica</h6>
                  Acquisisci competenze operative da applicare sul campo
                </p>
                <p className="description">
                  <h6 className="mini_title">Webinar settimanali</h6>
                  Partecipa  a incontri ed Eventi Live tenuti da professionisti
                </p>
                <p className="description">
                  <h6 className="mini_title">Community</h6>
                  Fai domande ai docenti e ti confronti con gli esperti del settore
                </p>
                <p className="description">
                  <h6 className="mini_title">Test e Quiz</h6>
                  Esercitati e verifica i tuoi progressi per conoscere il tuo grado di preparazione
                </p>
                <p className="description">
                  <h6 className="mini_title">CertiFinanZ</h6>
                  Convalida i tuoi traguardi e conquista il mondo del lavoro con il nostro attestato
                </p>
                <p className="description disabled">
                  <h6 className="mini_title">2 Mesi Gratuiti</h6>
                  Ottieni 60 giorni di apprendimento illimitato in regalo
                </p>
                <br/>
                <h1 className="text-center title">9,99€</h1>
                <div className="centered">
                  <Link to="/dashboard/account/payments" replace className="button bounce button m-0">Inizia Subito</Link>
                </div>
              </div>
            </Col>
            <Col md="4"  className="mb-3">
              <div className="block prices bounce">
                <h1 className="text-center">Annuale</h1>
                <p className="description">
                  <h6 className="mini_title">Academy</h6>
                  Accesso illimitato all'area
                </p>
                <p className="description">
                  <h6 className="mini_title">News</h6>
                  Trend del momento e novità finanziarie
                </p>
                <p className="description">
                  <h6 className="mini_title">Video corsi</h6>
                  Accedi senza limiti a più di 25 corsi didattici online
                </p>
                <p className="description">
                  <h6 className="mini_title">Lezioni esclusive</h6>
                  Segui più di 500 lezioni dove e quando vuoi
                </p>
                <p className="description">
                  <h6 className="mini_title">Formazione pratica</h6>
                  Acquisisci competenze operative da applicare sul campo
                </p>
                <p className="description">
                  <h6 className="mini_title">Webinar settimanali</h6>
                  Partecipa  a incontri ed Eventi Live tenuti da professionisti
                </p>
                <p className="description">
                  <h6 className="mini_title">Community</h6>
                  Fai domande ai docenti e ti confronti con gli esperti del settore
                </p>
                <p className="description">
                  <h6 className="mini_title">Test e Quiz</h6>
                  Esercitati e verifica i tuoi progressi per conoscere il tuo grado di preparazione
                </p>
                <p className="description">
                  <h6 className="mini_title">CertiFinanZ</h6>
                  Convalida i tuoi traguardi e conquista il mondo del lavoro con il nostro attestato
                </p>
                <p className="description">
                  <h6 className="mini_title">2 Mesi Gratuiti</h6>
                  Ottieni 60 giorni di apprendimento illimitato in regalo
                </p>
                
                <h1 className="text-center" id="month_price_wrong">119,98€</h1>
                <h1 className="text-center title" id="month_price">99€</h1>
                <div className="centered">
                  <Link to="/dashboard/account/payments" replace className="button bounce button m-0">Inizia Subito</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <a id="faq"/>
      <Container>
        {/* FAQ */}
        <div className="faq">
          <br/>
          <br/>
          <h1 className="main_title text-center animate">FAQ</h1>
          <br/>
          <Row className="animate">
            <Col md="1"></Col>
            <Col md="5">
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Che cos’è Finanz?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Finanz è la prima piattaforma gratuita accessibile a chiunque voglia migliorare le proprie conoscenze finanziarie e sviluppare nuove abilità pratiche da applicare nel mondo degli investimenti.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Perché scaricare l’App Finanz?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Avrai accesso gratuito a tantissimi percorsi formativi che ti garantiscono un apprendimento completo, aggiornato e adattato alle tue esigenze, per migliorare passo a passo le competenze finanziarie che vuoi tu.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Come scarico l’App di Finanz?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">L’App è disponibile gratuitamente per sistemi iOs e Android, basta aprire Apple Store o Play Store per scaricarla.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Devo pagare per scaricare l’App?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">No, il download è completamente gratuito e ti dà libero accesso a tutti i percorsi di studio disponibili.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Servono requisiti per studiare dentro Finanz?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Nessun requisito è necessario per iniziare i nostri percorsi, l’apprendimento è aperto a chiunque e soprattutto non è mai troppo tardi per cominciare a imparare!</p>
                </AccordionDetails>
              </Accordion>
            </Col>
            <Col md="5">
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Perché abbonarsi alla sezione Finanz Plus?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Solo gli abbonati hanno accesso ai video corsi disponibili e a quelli nuovi in uscita! Inoltre potrai partecipare ai Webinar settimanali e agli eventi tenuti dai nostri esperti, rivedere le registrazioni e conseguire il CertiFinanZ.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Dove posso seguire le lezioni?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Potrai seguire i contenuti video dal tuo smartphone, da pc e tablet, cambiando dispositivo ogni volta che vuoi. Dovrai semplicemente scaricare l’App ed eseguire l’accesso con le tue credenziali.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Posso continuare ad avere accesso ai video-corsi anche dopo averli completati?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Sì. Potrai continuare ad accedere al corso anche dopo averlo completato, a condizione che il tuo account sia attivo ed in regola.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Che cos’è il CertiFinanz?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Completando il tuo percorso formativo in Finanz Plus otterrai il CertiFinanz per attestare le tue nuove competenze e mostrate a tutti i traguardi che hai raggiunto.</p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="block my_accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="orange_icon"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Come si cancella l’abbonamento a Finanz Plus?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">Se ci ripensi puoi annullare il tuo abbonamento in pochi e semplici click da questo link</p>
                </AccordionDetails>
              </Accordion>
            </Col>
            <Col md="1"></Col>
          </Row>

          <br/>
          <br/>
          <br/>

          {/* ALLORA CHE ASPETTI */}
          <div className="waiting_for">
            <h1 className="main_title text-center animate">Cosa stai aspettando?</h1>
            <br/>
            <Col md="6" className="mx-auto animate">
              <h1 className="text-center description">Impara tutto sul mondo della finanza e <b>conquista il tuo futuro</b></h1>
            </Col>
            <br/>
            <div className="centered animate">
              <button className="bounce button">Registrati</button>
            </div>
          </div>
        </div>
      </Container>
      <CookieConsent
        location="bottom"
        buttonText="Capito!"
        expires={150}
        buttonStyle={{ background: "var(--details_color)", color : "#FFF", borderRadius: "10px"}}
      >
        Questo sito web utilizza i cookie per migliorare l'esperienza utente
      </CookieConsent>
    </div>
  )
}

export default Home