import React, { useState, useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Fade, IconButton } from '@mui/material'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CountUp from 'react-countup';

import "./style.css"
import ConfirmAction from '../confirm_action'

function MultipleChoiceQuestion(props) {
    // return <div style={{"width":"40px","height":"40px","background-color":"red"}}>QUI</div>
    const [reload, setReload] = useState(true)
    let user = props.user
    let deletable = props.deletable     //in this case question is a QuestionCreatorControllor
                                        // otherwise a QuestionController
    let question = deletable ? props.question.question : props.question
    let questionCreator = deletable ? props.question : null

    let setStatus = props.setStatus??function(){};
    let editAnswer = props.editAnswer
    let [questionsCompletion, setQuestionsCompletion] = props.completion??['',()=>{}]

    const [correctAnswer, setCorrectAnswer] = useState({isCorrect: false, message: ""})
    const [answerGiven, setanswerGiven] = useState(false)
    const [confirmInfo, setConfirmInfo] = useState({confirm: undefined, refute: undefined})

    async function addRemoveChoice(id) {
        if(question.isChoiceSelected(id)) question.removeSelectedChoice(id)
        else {
            question.addSelectedChoice(id)
            if(!deletable) {
                // send user answer
                let info = await question.sendUserAnswer(id)
                let isCorrect = undefined
                let xp = undefined

                if(info != undefined) {
                    isCorrect = info['isCorrect']
                    xp = info['xp']
                }

                if(isCorrect != undefined) {
                    user.setXP(user.getXP() + xp)
                    setCorrectAnswer({isCorrect: isCorrect, message: question.getChoices()[id]['description'], xp: xp})
                    setanswerGiven(true)
                    setTimeout(() => setanswerGiven(false), 3000)
                } 
            }
        }
        setReload((p)=>!p)
        
        setStatus((p)=>p+1)
    }

    
    function handleDelete(e, id) {
        e.stopPropagation()
        setConfirmInfo({confirm: () => questionCreator.deleteItem(id), refute: undefined})
    }

    useEffect(()=>{
        if(!deletable && correctAnswer['isCorrect']){
            let tmpQuestions = {...questionsCompletion}
            tmpQuestions.completion[question.id] = true
            setQuestionsCompletion(tmpQuestions)
        }

    },[correctAnswer])

    useEffect(() => {
        setanswerGiven(false)
    }, [question])
    

    return (
        <div className={!deletable ? "multiple_choice centered" : "multiple_choice"}>
            {!deletable ? <p>{question && question.getTitle()}</p> : ""}
            {
                //stimulate reloading...
                reload===reload && Object.keys(question.getChoices()).map((id) => 
                    <div key={id}>
                        <div className="choice mb-3 max_width" onClick={() => addRemoveChoice(id)}>
                            {
                                deletable ? 
                                <>
                                    <div className="display_inline max_width">
                                        <div className={"tick " + (question.isChoiceSelected(id) ? "selected" : "")}></div>
                                        {
                                            typeof question.getChoices()[id] == "string" ?
                                            <p className="mb-0">{question.getChoices()[id]}</p> :
                                            <div>
                                                <p className="mb-0">{question.getChoices()[id]['title']}</p>
                                                <p className="mb-0 thin">{question.getChoices()[id]['description']}</p>
                                            </div>
                                        }
                                        <IconButton className="m-0 p-0" onClick={(e) => handleDelete(e, id)}>
                                            <DeleteIcon className="orange_icon"/>
                                        </IconButton>
                                        {
                                            editAnswer != undefined ? 
                                                <IconButton className="m-0 p-0" onClick={(e) => {
                                                    e.stopPropagation()
                                                    editAnswer(question.getChoices()[id]['title'], question.getChoices()[id]['description'])
                                                }
                                                    }>
                                                    <AutoFixHighIcon className="orange_icon"/>
                                                </IconButton> :
                                                ""
                                        }
                                    </div>
                                </> :
                                <>
                                    <div className={"tick " + (question.isChoiceSelected(id) ? "selected" : "")}></div>
                                    {
                                        typeof question.getChoices()[id] == "string" ?
                                        <p className="mb-0">{question.getChoices()[id]}</p> :
                                        <p className="mb-0">{question.getChoices()[id]['title']}</p>
                                    }
                                </>
                            }
                        </div>
                    </div>
                )
            }
            {props.children}
            {
                !deletable && answerGiven ?
                <Fade in={!deletable && answerGiven} style={{ transitionDuration: "800ms" }}>
                    <div className="verify_answer">
                        <h1>{ correctAnswer['isCorrect'] ? "Corretto!" : "Errato!" }</h1>
                        <p>{correctAnswer['message']}</p>
                        {
                            correctAnswer['xp'] != undefined && correctAnswer['xp'] != 0 && user != undefined ?
                                <div className="text-center">
                                    <h5 style={{ color: "var(--details_color)"}}><CountUp duration={1} end={user.getXP()}>xp</CountUp>xp</h5>
                                    <p className="text-center" style={{ color: "var(--details_color)"}}>+ {correctAnswer['xp']}xp</p> 
                                </div> :
                                ""
                        }
                    </div>
                </Fade> :
                ""
            }
            <ConfirmAction action={confirmInfo} closeFunction={() => setConfirmInfo({confirm: undefined, refute: undefined})}/>
        </div>
    )
}

export default MultipleChoiceQuestion
