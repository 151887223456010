import React from 'react'

function BooksPage() {
    return (
        <div>
            <h1>Account Page</h1>
        </div>
    )
}

export default BooksPage
