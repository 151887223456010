import User from "../models/user";
import NewsListController from "./news_list_controller";
import CourseListController from "./courses_list_controller"
import AccademyListController from "./accademy_list_controller"
import AuthJWT from '../utils/auth_jwt'
import WebinarList from "./webinar_list"
import {api_url} from "../App"
import $ from "jquery"
import { beforeSendCallback } from "../utils";

class UserController extends User{
    constructor() {
        super()
        this.news = new NewsListController()
        this.news.setOverrideState((() => this.updateState()).bind(this))
        this.courses = new CourseListController()
        this.courses.setOverrideState((() => this.updateState()).bind(this))
        this.modules = new AccademyListController()
        this.modules.setOverrideState((() => this.updateState()).bind(this))
        this.webinar = new WebinarList()
        this.webinar.setOverrideState((() => this.updateState()).bind(this))
        this.getInterestsField().setOverrideState((() => this.updateState()).bind(this))
        this.justLogged = false
        this.state = undefined
    }

    setState(state) {
        this.state = state
        this.updateState()
    }
    
    loadInfo({ accessToken, refreshToken, name, surname, birthday, email, username, password, codice_invito, avatar, xp, language, league, consecutiveDaysJoin, role, createdCodes, interests_field, isPremium }) {
        this.accessToken = accessToken != undefined ? accessToken : this.accessToken
        this.refreshToken = refreshToken != undefined ? refreshToken : this.refreshToken
        this.name = name != undefined ? name : this.name
        this.surname = surname != undefined ? surname : this.surname
        this.birthday = birthday != undefined ? birthday : this.birthday
        this.email = email != undefined ? email : this.email
        this.username = username != undefined ? username : this.username
        this.password = password != undefined ? password : this.password
        this.codice_invito = codice_invito != undefined ? codice_invito : this.codice_invito
        this.avatar = avatar != undefined ? avatar : this.avatar
        this.xp = xp != undefined ? xp : this.xp
        this.language = language != undefined ? language : this.language
        this.league = league != undefined ? league : this.league
        this.consecutiveDaysJoin = consecutiveDaysJoin != undefined ? consecutiveDaysJoin : this.consecutiveDaysJoin
        this.role = role != undefined ? role : this.role
        this.createdCodes = createdCodes != undefined ? createdCodes : this.createdCodes
        this.interests_field = interests_field != undefined ? interests_field : this.interests_field
        this.isPremium = isPremium != undefined ? isPremium : this.isPremium
    }

    load(info) {
        this.loadInfo(info)
        this.updateState()
    }
    
    updateState() {
        if(this.state != undefined) {
            let newIntstance = new UserController()
            Object.assign(newIntstance, this)
            this.state(newIntstance)
        }
    }

    capitalizeFirstLetter(string) {
        if(string != "") {
            let first_letter = string[0].toUpperCase()
            return first_letter + string.slice(1, string.length)
        } else return string
    }

    getAccessToken() {return AuthJWT.getAccessToken()}
    getRefreshToken() {return AuthJWT.getRefreshToken()}
    // getAccessToken() {return this.accessToken}
    // getRefreshToken() {return this.refreshToken}
    getName() {return this.name}
    getSurname() {return this.surname}
    getBirthday() {return this.birthday}
    getEmail() {return this.email}
    getUsername() {return this.username}
    getPassword() {return this.password}
    getCodiceInvito() {return this.codice_invito}
    getAvatar() {return this.avatar}
    getXP() {return this.xp}
    getLanguage() {return this.language}
    getLeague() {return this.league}
    getConsecutiveDaysJoin() {return this.consecutiveDaysJoin}
    getRole() {return this.role}
    getCreatedCodes() {return this.createdCodes}
    getInterestsField() { return this.interests_field }
    getIsPremium() { return this.isPremium }

    setAccessToken(accessToken, _auto_save = true) {
        this.accessToken = accessToken
        AuthJWT.setAccessToken(accessToken);
        if(_auto_save) this.updateState()
    }
    
    setRefreshToken(refreshToken, _auto_save = true) {
        this.refreshToken = refreshToken
        AuthJWT.setRefreshToken(refreshToken);
        if(_auto_save) this.updateState()
    }

    setName(name, _auto_save = true) {
        name = this.capitalizeFirstLetter(name)
        this.name = name
        if(_auto_save) this.updateState()
    }

    setSurname(surname, _auto_save = true) {
        surname = this.capitalizeFirstLetter(surname)
        this.surname = surname
        if(_auto_save) this.updateState()
    }

    setBirthday(birthday, _auto_save = true) {
        this.birthday = birthday
        if(_auto_save) this.updateState()
    }

    setEmail(email, _auto_save = true) {
        this.email = email
        if(_auto_save) this.updateState()
    }

    setUsername(username, _auto_save = true) {
        this.username = username
        if(_auto_save) this.updateState()
    }

    setPassword(password, _auto_save = true) {
        this.password = password
        if(_auto_save) this.updateState()
    }

    setCodiceInvito(codice_invito, _auto_save = true) {
        this.codice_invito = codice_invito
        if(_auto_save) this.updateState()
    }

    setAvatar(avatar, _auto_save = true) {
        this.avatar = avatar
        if(_auto_save) this.updateState()
    }

    setXP(xp, _auto_save = true) {
        this.xp = xp
        if(this._auto_save) this.updateState()
    } 

    setLanguage(language, _auto_save = true) {
        this.language = language
        if(this._auto_save) this.updateState()
    } 

    setLeague(league, _auto_save = true) {
        this.league = league
        if(this._auto_save) this.updateState()
    } 

    setConsecutiveDaysJoin(consecutiveDaysJoin, _auto_save = true) {
        this.consecutiveDaysJoin = consecutiveDaysJoin
        if(this._auto_save) this.updateState()
    } 

    setRole(role, _auto_save = true) {
        this.role = role
        if(this._auto_save) this.updateState()
    }

    setCreatedCodes(createdCodes, _auto_save = true) {
        this.createdCodes = createdCodes
        if(this._auto_save) {
            this.updateState()
        }
    }

    setInterestsField(interests_field, _auto_save = true) {
        this.interests_field = interests_field
        if(this._auto_save) {
            this.updateState()
        }
    }

    setIsPremium(isPremium, _auto_save = true) {
        this.isPremium = isPremium
        if(this._auto_save) {
            this.updateState()
        }
    }

    isValidBirthday() {
        return new Date() > new Date(this.getBirthday())
    }
    
    isValidEmail(email = this.getEmail()) {
        return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    isValidUsername() {
        // DA SVILUPPARE
        return true
    }

    exportInfo() {
        let _user = new UserController()
        _user.loadInfo(this)
        return _user
    }

    makeUserFieldEmpty() {
        Object.assign(this, new User())
        this.updateInfo()
    }

    isLogged() {
        // let accessToken = window.localStorage.getItem("accessToken")    // !!
        // let refreshToken = window.localStorage.getItem("refreshToken")  // !!
        let accessToken = AuthJWT.getAccessToken()
        let refreshToken = AuthJWT.getRefreshToken()
        if(accessToken != undefined && refreshToken != undefined) {
            // just logged
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
            return true
        } else {
            // not logged
            return false
        }
    }

    
    getUserInfo(f = undefined) {
        // DA SVLUPPARE
        if(f != undefined)
            f()
    }

    canIDoSomethingSpecial(){
        return this.getRole().length != 0
    }

    canI(action) {
        //console.log(this.getRole().filter(item => item['slug'] == "super-admin-news"))
        // super admin can do everything
        if(this.getRole().filter(item => item['slug'] == "super-admin").length != 0) return true
        // other roles
        switch (action) {
            case "create_academy":
                if(this.getRole().filter(item => item['slug'] == "super-admin-academy").length != 0) return true
                if(this.getRole().filter(item => item['slug'] == "admin-academy").length != 0) return true
                break
            case "create_news":
                if(this.getRole().filter(item => item['slug'] == "super-admin-news").length != 0) return true
                if(this.getRole().filter(item => item['slug'] == "admin-news").length != 0) return true
                break
            case "create_course":
                if(this.getRole().filter(item => item['slug'] == "admin-course").length != 0) return true
                break
            case "create_school":
                if(this.getRole().filter(item => item['slug'] == "admin-school").length != 0) return true
                break
            case "create_tag":
                if(this.getRole().filter(item => item['slug'] == "admin-tag").length != 0) return true
                break
            case "create_webinar":
                if(this.getRole().filter(item => item['slug'] == "admin-webinar").length != 0) return true
                break
            case "create_academy_category":
                if(this.getRole().filter(item => item['slug'] == "super-admin-academy").length != 0) return true
                break
            case "create_news_category":
                if(this.getRole().filter(item => item['slug'] == "super-admin-news").length != 0) return true
                break
            case "manage_roles":
                if(this.getRole().filter(item => item['slug'] == "admin-roles").length != 0) return true
                break
        }

        return false
    }

    async isUsernameValid(username = this.getUsername()) {
        let accessToken = this.getAccessToken()
        let isValid = false

        await $.ajax({
            type : "POST",
            url : api_url + "check_username",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : (request) => request.setRequestHeader("Authorization", "Bearer " + accessToken),
            data: JSON.stringify({
                username: username
            }),
            success: (data) => isValid = data['isValid']
        })

        return isValid
    }

    async isPasswordValid(password = this.getPassword()) {
        let accessToken = this.getAccessToken()
        let isValid = false

        await $.ajax({
            type : "POST",
            url : api_url + "check_password",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : (request) => request.setRequestHeader("Authorization", "Bearer " + accessToken),
            data: JSON.stringify({
                password: password
            }),
            success: (data) => isValid = data['isPasswordValid']
        })

        return isValid
    }

    async areCredentialsCorrect(username, password, callback = console.log) {
        return $.ajax({
            type : "POST",
            url : api_url +  "/login",
            xhrFields: {
                withCredentials: true
            },
            cache: false,
            crossDomain: true,
            data : {
                username : username,
                password : password
            },
            success : async (data) => {
                let isError = false
                
                if(data['emailVerification'] !== undefined && !data['emailVerification']) {
                    isError = true
                    data = "Account non ancora attivato"
                    callback(isError, data)
                    return
                }
                
                let accessToken = data.access_token
                let refreshToken = data.refresh_token
                let auto_update = false
                this.setAccessToken(accessToken, auto_update)
                this.setRefreshToken(refreshToken, auto_update)
                await this.setInfo(() => callback(isError, data))
            },
            error : (message) => {
                let isError = true
                let data = "Username o password errati"
                callback(isError, data)
            }
        })
    }

    async activateUser(code) {
        let accessToken = this.getAccessToken()
        let error = false

        try {
            await $.ajax({
                type : "POST",
                url : api_url + "user/activate_user/" + code,
                accepts: "application/json",
                contentType: "application/json",
                beforeSend : (request) => request.setRequestHeader("Authorization", "Bearer " + accessToken),
                success: () => error = false,
                error: () => error = true
            })
        } catch {
            error = true
        }

        return error

    }

    async putInfo() {
        let accessToken = this.getAccessToken()

        $.ajax({
            type : "PUT",
            url : api_url + "user",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : (request) => request.setRequestHeader("Authorization", "Bearer " + accessToken),
            data: JSON.stringify({
                name: this.getName(),
                surname: this.getSurname(),
                birthDay: this.getBirthday(),
            })
        })
    }

    async setInfo(callback) {

        await $.ajax({
            type : "GET",
            url : api_url + "user",
            contentType : "application/json",
            beforeSend : beforeSendCallback,
            success : (data) => {
                let info = {
                    username : data.username,
                    name : data.personalData.name,
                    surname : data.personalData.surname,
                    email : data.personalData.emailAddress,
                    birthday : data.personalData.birthDay,
                    language : data.personalData.language,
                    xp : data.gamificationData.xp,
                    league : data.gamificationData.league,
                    consecutiveDaysJoin : data.gamificationData.consecutiveDaysJoin,
                    role : data.roles,
                    isPremium : data.subscription.isPremium
                }
                this.load(info)
                if(callback     != undefined) callback()
            },
            error : (message) => {
                if(message.status == 401) {
                    this.refreshing(callback)
                }
            }
        })
    }
    
    async refreshing(callback) {
        let refreshToken = AuthJWT.getRefreshToken()

        $.ajax({
            type: "POST",
            url : api_url + "refreshToken",
            contentType: "application/json",
            beforeSend : (request) => request.setRequestHeader("Authorization", "Bearer " + refreshToken),
            success: (data) => {
                this.setAccessToken(data['access_token'])
                this.setInfo(callback)
            },
            error: (message) => console.log(message)
        })
    }

    logout() {
        AuthJWT.setAccessToken("");
        AuthJWT.setRefreshToken("");
    }

    async signIn() {
        await $.ajax({
            type : "POST",
            url : api_url + "signin",
            accepts: "application/json",
            contentType: "application/json",
            data: JSON.stringify({
                username: this.getUsername(),
                password: this.getPassword(),
                name: this.getName(),
                surname: this.getSurname(),
                birthDay: this.getBirthday(),
                emailAddress: this.getEmail(),
                language: "IT"
                // language: this.getLanguage()
            })
        })
    }

    generateCode(roles) {
        return $.ajax({
            type : "POST",
            url : api_url + "code/invitation_codes",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : beforeSendCallback,
            data: JSON.stringify({
                "roles_slug": roles
            }),
            success: (data) => this.setCodiceInvito(data['code']['code']),
        })

    }

    async redeemCode() {
        return $.ajax({
            type : "POST",
            url : api_url + "code/redeem_code",
            contentType: "application/json",
            beforeSend : beforeSendCallback,
            data: JSON.stringify({
                "code": this.getCodiceInvito()
            }),
        })
    }

    // codes not used
    getCreatedCodesFromServer() {
        //let access_token = window.localStorage.getItem('accessToken')
        $.ajax({
            type : "GET",
            url : api_url + "code/invitation_codes",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : beforeSendCallback,
            success: (data) => {
                let list = []
                for(let codeObj of data['codes']) {
                    if(codeObj['oneTimeCode'] != 0) {
                        let code = codeObj['code']
                        let roles = codeObj['roles']
                        let roleNames = ""
                        for(let role of roles) roleNames += role['name'] + ", "
                        let usages = codeObj['usages']
                        list.push({code: code, roles: roleNames.slice(0, -2), usages: usages}) // removes comma
                    }
                }
                this.setCreatedCodes(list)
                this.updateState()
            }
        })
    }

    async deleteCode(code) {
        //let access_token = window.localStorage.getItem('accessToken')

        $.ajax({
            type : "DELETE",
            url : api_url + "/code/invitation_code/" + code,
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : beforeSendCallback,
            success: () => this.getCreatedCodesFromServer()
        })
    }

    async getAllRoles() {
        //let access_token = window.localStorage.getItem("accessToken")
        let list = []
        await $.ajax({
            type : "GET",
            url : api_url + "roles",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : beforeSendCallback,
            success: (data) => list = data['roles']
        })
        return list
    }

    // used to restore password
    async sendMail(email) {
        //let access_token = window.localStorage.getItem('accessToken')

        return $.ajax({
            type : "POST",
            url : api_url + "recover_password/send_mail",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend : beforeSendCallback,
            data: JSON.stringify({
                email: email
            })
        })
    }

    async recoverPassword(password, confirmPassword, code) {
        //let access_token = window.localStorage.getItem('accessToken')
        let error = false

        try {
            await  $.ajax({
                type : "POST",
                url : api_url + "recover_password/" + code,
                accepts: "application/json",
                contentType: "application/json",
                beforeSend : beforeSendCallback,
                data: JSON.stringify({
                    password: password,
                    confirm_password: confirmPassword,
                }),
            })
        } catch {
            error = true
        }

        return error
    }
}

export default UserController