import Module from "../models/module"
import QuestionCreationController from "./question_creation_controller"
import QuestionController from "./question_controller"
import {api_url} from "../App"
import $ from "jquery"
import { beforeSendCallback } from "../utils"

class ModuleController extends Module {
    constructor() {
        super()
        this.state = undefined
        this.overrideState = undefined
    }

    setState(state) {this.state = state}

    loadInfo({id = "", author = "", socialPage = "", argument = "", wallpaper = "", title = "", description = "", difficultyLevel = "", position = 1, modules = {}, publishDate = "", n_modules = "", time = 30, completedNotes = [], quiz_type = "academy"}) {
        this.id = id
        this.author = author
        this.socialPage = socialPage
        this.argument = argument
        this.wallpaper = wallpaper
        this.title = title
        this.description = description
        this.difficultyLevel = difficultyLevel
        this.position = position
        this.modules = modules
        this.publishDate = publishDate
        this.n_modules = n_modules
        this.time = time
        this.position = position
        this.completedNotes = completedNotes
        this.quiz_type = quiz_type
    }

    load(info, creation_mode = true) {
        this.loadInfo({...info})

        let modules = this.getModules()
        for(let moduleId of Object.keys(modules)) {
            for(let pageId of Object.keys(this.getAllPages(moduleId))) {
                if(this.getPageType(moduleId, pageId) == 'quiz') {
                    let quizObj = this.getPageContent(moduleId, pageId)
                    let quiz
                    if(creation_mode) {
                        quiz = new QuestionCreationController()
                        quiz.question.setAcceptedChoices(1)
                        quiz.setOverrideState((() => this.updateInfo()).bind(this))
                        quiz.load({question: {...quizObj.question.question, selectedChoices: [...quizObj.question.selectedChoices]}, correctChoices : undefined})
                    } else {
                        quiz = new QuestionController()
                        quiz.setAcceptedChoices(1)
                        quiz.setOverrideUpdateInfo((() => this.updateInfo()).bind(this))
                        quiz.load({...quizObj.question})
                    }
                    modules[moduleId]['pages'][pageId]['content'] = quiz
                }
            }
        }
        this.updateInfo()
    }

    // getting info from API
    async loadById(moduleId, fullGet = true) {
        // getting module info
        this.getModuleInfo(moduleId)
        // get notes
        let notesId = await this.getNotes(moduleId)

        // get pages per notes
        if(fullGet) {
            for(let noteId of notesId) {
                this.loadNote(noteId)
            }
        }

    }

    // API function
    getModuleInfo(moduleId) {
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "GET",
            url: api_url + "academy/module/" + moduleId,
            accepts: "application/json",
            contentType: "application/json",
            beforeSend: beforeSendCallback,
            success: (data) => {
                let title = data['title']
                let description = data['description']
                let argument = data['argument_slug']
                let difficultyLevel = data['difficulty'] == undefined ? "" : data['difficulty']
                let wallpaper = data['coverImageLink']
                let position = data['order']

                this.setId(moduleId)
                this.setTitle(title)
                this.setDescription(description)
                this.setArgument(argument)
                this.setDifficultyLevel(difficultyLevel)
                this.setWallpaper(wallpaper)
                this.setPosition(position)
                this.setAuthor()
            },
            error: (message) => console.log(message)
        })
    }

    // API function
    async getNotes(moduleId, writeMode = true) {
        //let accessToken = window.localStorage.getItem("accessToken")
        let notes = []
        
        await $.ajax({
            type: "GET",
            url: api_url + "academy/module/" + moduleId + "/notes",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend: beforeSendCallback,
            success: (data) => {
                let notesInfo = data['notes']
                for(let note of notesInfo) {
                    let title = note['title']
                    let id = note['slug']
                    let isLiked = note['isLiked']
                    let isSaved = note['isSaved']
                    notes.push(id)

                    if(writeMode)
                        this.addModule({
                            id : id,
                            title : title,
                            position : Object.keys(this.getModules()).length + 1,
                            pages : {},
                            isLiked : isLiked,
                            isSaved : isSaved
                        })
                }
            },
            error: (message) => console.log(message)
        })
        return notes
    }

    // API function
    async getPageFromServer(noteId, pageNumber, writeMode = true) {
        //let accessToken = window.localStorage.getItem("accessToken")
        let next = false

        await $.ajax({
            type: "GET",
            url: api_url + "academy/note/" + noteId + "/pages",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
            data: {
                page: pageNumber,
            },
            success: (data) => {
                if(writeMode) next = data['next_page'] != undefined
                else next = data?.page

                if(data.page != null && writeMode) {
                    let info = {
                        pageId : data['page']['id'],
                        content : data['page']['content'],
                        quiz : data['page']['quiz'],
                        position : data['page']['order'],
                        wallpaper : data['page']['coverImageLink'] == null ? "" : data['page']['coverImageLink'],
                    }

                    if(info['quiz'] == null) this.addPage(noteId, info)
                    else this.addQuiz(noteId, info)
                } 
            }
        })

        return next
    }

    async loadNote(noteId, updateMode = false) {
        let pageNumber = 1
        let next = true

        // stops when there aren't no more pages
        while(next) {
            next = await this.getPageFromServer(noteId, pageNumber)
            pageNumber++
        }

        if(updateMode) this.updateInfo()
    }

    async setChapterFinished(moduleId) {
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "POST",
            url: api_url + "academy/note/" + moduleId +"/finish",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    updateInfo() {
        if(this.state != undefined) {
            let newIntstance = new ModuleController()
            Object.assign(newIntstance, this)
            this.state(newIntstance)
        }
        else if(this.overrideState != undefined) 
            this.overrideState()
    }

    async loadCompletedNotes(moduleId = this.getId()) {
        
        //let accessToken = window.localStorage.getItem("accessToken")
        let info = []
        
        await $.ajax({
            type: "GET",
            url: api_url + "academy/module/" + moduleId + "/notes/finished",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
            success: (data) => {
                let notes = data['notes'].sort((a,b) => a['position'] > b['position'] ? -1 : 1)
                let notesId = notes.map((item) => item['slug'])
                this.setCompletedNotes(notesId)
            }
        })

        return info
    }

    async likeNote(noteId) {
        //let accessToken = window.localStorage.getItem("accessToken")

        await $.ajax({
            type: "POST",
            url: api_url + "academy/note/" + noteId + "/like",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    async unlikeNote(noteId) {
        //let accessToken = window.localStorage.getItem("accessToken")

        await $.ajax({
            type: "DELETE",
            url: api_url + "academy/note/" + noteId + "/like",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    async saveNote(noteId) {
        //let accessToken = window.localStorage.getItem("accessToken")

        await $.ajax({
            type: "POST",
            url: api_url + "academy/note/" + noteId + "/save",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    async unsaveNote(noteId) {
        //let accessToken = window.localStorage.getItem("accessToken")

        await $.ajax({
            type: "DELETE",
            url: api_url + "academy/note/" + noteId + "/save",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    getId() {return this.id}
    getAuthor() {return this.author}
    getSocialPage() {return this.socialPage}
    getArgument() {return this.argument}
    getWallpaper() {return this.wallpaper}
    getTitle() {return this.title}
    getDescription() {return this.description}
    getDifficultyLevel() {return this.difficultyLevel}
    getPosition() {return this.position}
    getModules() {return this.modules}
    getPublishDate() {return this.publishDate}
    getNModules() {return this.n_modules}
    getTime() {return this.time}
    getPosition() {return this.position}
    getCompletedNotes() {return this.completedNotes}

    setId(id, _auto_save = true) {
        this.id = id
        if(_auto_save) this.updateInfo()
    }
    setAuthor(author, _auto_save = true) {
        this.author = author
        if(_auto_save) this.updateInfo()
    }
    setSocialPage(socialPage, _auto_save = true) {
        this.socialPage = socialPage
        if(_auto_save) this.updateInfo()
    }
    setArgument(argument, _auto_save = true) {
        this.argument = argument
        if(_auto_save) this.updateInfo()
    }
    setWallpaper(wallpaper, _auto_save = true) {
        this.wallpaper = wallpaper
        if(_auto_save) this.updateInfo()
    }
    setTitle(title, _auto_save = true) {
        this.title = title
        if(_auto_save) this.updateInfo()
    }
    setDescription(description, _auto_save = true) {
        this.description = description
        if(_auto_save) this.updateInfo()
    }
    setDifficultyLevel(difficultyLevel, _auto_save = true) {
        this.difficultyLevel = difficultyLevel
        if(_auto_save) this.updateInfo()
    }
    setPosition(position, _auto_save = true) {
        this.position = position
        if(_auto_save) this.updateInfo()
    }
    setModules(modules, _auto_save = true) {
        this.modules = modules
        if(_auto_save) this.updateInfo()
    }
    setPublishDate(publishDate, _auto_save = true) {
        this.publishDate = publishDate
        if(_auto_save) this.updateInfo()
    }
    setNModules(n_modules, _auto_save = true) {
        this.n_modules = n_modules
        if(_auto_save) this.updateInfo()
    }
    setTime(time, _auto_save = true) {
        this.time = time
        if(_auto_save) this.updateInfo()
    }

    setOverrideState(callback) {
        this.overrideState = callback
    }

    setPageContent(moduleId, pageId, content) {
        let page = this.getPage(moduleId, pageId)
        page['content'] = content
        this.updateInfo()
    }

    setPageWallpaper(moduleId, pageId, wallpaper) {
        let page = this.getPage(moduleId, pageId)
        page['wallpaper'] = wallpaper
        this.updateInfo()
    }
    
    setPosition(position, _auto_save = true) {
        this.position = position
        if(_auto_save) this.updateInfo()
    }

    setCompletedNotes(completedModule, _auto_save = true) {
        this.completedNotes = completedModule
        if(_auto_save) this.updateInfo()
    }

    getModuleById(id) {
        return this.getModules()[id]
    }

    getAllPages(moduleId) {
        if(this.getModuleById(moduleId) != undefined)
            return this.getModuleById(moduleId).pages
        else return {}
    }

    getPage(moduleId, pageId) {
        return this.getAllPages(moduleId)[pageId]
    }

    getModuleTitle(moduleId) {
        if(this.getModuleById(moduleId) != undefined)
            return this.getModuleById(moduleId)['title']
        else return ""
    }

    setModuleTitle(moduleId, title) {
        let newModules = this.getModules()
        newModules[moduleId]['title'] = title
        this.setModules(newModules)
        this.updateInfo()
    }

    addModule(data = undefined) {
        let _format = {}
        let newId = ""
        let newModules = this.getModules()

        if(data == undefined) {
            // empty module creation
            _format = {
                title: "Nuovo Modulo",
                position: Object.keys(this.getModules()).length + 1,
                pages: {},
                isLiked : false,
                isSaved : false
            }
            newId = "_0"
            while(Object.keys(newModules).includes(newId)) newId = "_" + Math.random() * 10
        } else {
            // create module from info
            newId = data['id']
            _format = data
        }
        
        newModules[newId] = _format
        this.setModules(newModules)
        this.updateInfo()
    }

    async addPageObj(moduleId, type, info = undefined) {
        // default values
        let newModule = this.getModuleById(moduleId)
        let newId = "_0"
        let position = Object.keys(newModule['pages']).length + 1
        let title = "Nuova Pagina"
        let wallpaper = ""
        let content = ""
        let _questionCreationController = new QuestionCreationController()
        _questionCreationController.setOverrideState((() => this.updateInfo()).bind(this))
        _questionCreationController.question.setAcceptedChoices(undefined)
        _questionCreationController.question.setType(this.quiz_type)

        if(info != undefined) {
            title = info['title']
            newId = info['pageId']
            position = info['position']
            wallpaper = info['wallpaper']
            
            // create page from info
            if(type == "quiz") {
                let quiz = info['quiz']
                let quizTitle = quiz['question']
                let quizId = quiz['slug']
                let quizAnswers = quiz['answers']
                let quizImage = quiz['coverImageLink'] == null ? "" : quiz['coverImageLink']
                _questionCreationController.question.setId(quizId)
                _questionCreationController.question.setTitle(quizTitle)
                _questionCreationController.question.setImage(quizImage)
                _questionCreationController.question.setAcceptedChoices(1)

                for(let answer of quizAnswers) {
                    let answerId = answer['slug']
                    let answerTitle = answer['answer']
                    let answerDescription = answer['description']
                    _questionCreationController.addItem({
                        title: answerTitle,
                        description: answerDescription
                    }, answerId)
                }

                // set correct choice 
                let quizContent = await _questionCreationController.question.loadById(quizId)
                quizContent?.answers.forEach(item => {
                    if(item?.isCorrect)
                        _questionCreationController.question.addSelectedChoice(item?.slug)

                })
            } else {
                content = info['content']
            }
        } else {
            // create empty page
            while(Object.keys(newModule.pages).includes(newId)) newId = "_" + Math.random() * 10
        }

        newModule.pages[newId] = {
            id: newId,
            wallpaper: wallpaper,
            type: type,
            position: position,
            title: "Nuova pagina",
            content: type == 'page' ?
                        content :
                        _questionCreationController
        }

        this.updateInfo()
    }

    addPage(moduleId, info = undefined) {
        this.addPageObj(moduleId, 'page', info)
    }

    addQuiz(moduleId, info = undefined) {
        this.addPageObj(moduleId, 'quiz', info)
    }

    getPageType(moduleId, pageId) {
        if(this.getModuleById(moduleId) != undefined)
            if(this.getModuleById(moduleId)['pages'] != undefined)
                if(this.getModuleById(moduleId)['pages'][pageId] != undefined)
                    return this.getModuleById(moduleId)['pages'][pageId]['type']
        return ""
    }

    getPageTitle(moduleId, pageId) {
        return this.getModuleById(moduleId)['pages'][pageId]['title']
    }

    getPageWallpaper(moduleId, pageId) {
        return this.getModuleById(moduleId)['pages'][pageId]['wallpaper']
    }

    getPageContent(moduleId, pageId) {
        if(this.getPage(moduleId, pageId) != undefined)
            return this.getPage(moduleId, pageId).content
        else return ""
    }

    exportInfo() {
        let modules = JSON.parse(JSON.stringify(this.getModules()))
        
        return {
            id: this.getId(),
            author : this.getAuthor(),
            socialPage : this.getSocialPage(),
            argument : this.getArgument(),
            wallpaper : this.getWallpaper(),
            title : this.getTitle(),
            description : this.getDescription(),
            difficultyLevel : this.getDifficultyLevel(),
            position: this.getPosition(),
            modules : modules,
        }
    }
}

export default ModuleController