class Question {
    constructor() {
        this.id = ""
        this.title = ""
        this.image = ""
        this.choices = {}
        this.acceptedChoices = 1
        this.type = undefined
        this.completed = false
    }
}

export default Question