import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import MultipleChoiceQuestion from '../../components/multiple_choice_question'
import ExperienceQuestion from '../../controllers/experience_question_controller'
import QuestionController from '../../controllers/question_controller'
import Popup from '../../components/popup'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function SigninSection(props) {
    let user = props.user
    let routes = props.routes
    let setIsSigninIn = props.setIsSigninIn

    let navigate = useNavigate()

    const [experienceQuestion, setExperienceQuestion] = useState(new ExperienceQuestion())
    const [languageQuestion, setLanguageQuestion] = useState(new QuestionController())
    const [meetingQuestions, setMeetingQuestions] = useState([])
    const [currentSection, setCurrentSection] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [status, setStatus] = useState({
        error: null,
        description: null
    })
    const [section, setSection] = useState([
        (props) => <NameSurnameBirthday {...props}/>,
        (props) => <EmailUsernamePassword {...props} />,
        (props) => <ActivationSection {...props} />

        // NOTE: experience question added in the useEffect
        // NOTE: insert code section added in the useEffect
    ])

    async function scrollQuestion(n) {
        if(0 <= currentSection + n <= section.length) {
            if(n == 1) {
                if(!status.error) {
                    let error = false
                    if(currentSection == 1) {
                        setLoading(true)
                        let isValid = await user.isUsernameValid()
                        
                        
                        if(!isValid) {
                            setStatus({error: true, description: "Nome utente gia esistente"})
                            setShowPopup(true)
                        } else {
                            isValid = await user.isPasswordValid()
                            if(!isValid) {
                                setStatus({error: true, description: "Password non valida"})
                                setShowPopup(true)
                            }else{
                                await user.signIn()
                                .then(() => error = {error: false, description: "Utente registrato"})
                                .catch((data) => data['responseJSON']['message']?.isPasswordValid !== undefined ? 
                                    error = {error: true, description: "Password non valida"} : 
                                    error = {error: true, description: "Nome o email già esistenti"}
                                )
                                setStatus(error)
                                setShowPopup(true)
                            }
                        }

                        if(isValid) error = false
                    }

                    // signin 
                    if(currentSection + n === section.length) {
                        setLoading(true)
                        
                        
                        // try to login
                        // if(!error['error']) await user.areCredentialsCorrect(user.getUsername(), user.getPassword(), () => {})

                        
                        error = error['error']
                    }
                    
                    if(currentSection + n == section.length) {
                        setIsSigninIn(false)
                    }
                    
                    if(!error) setCurrentSection(currentSection + n)
                    setLoading(false)
                } else setShowPopup(true)
            } else setCurrentSection(currentSection + n)
        }
    }

    useEffect(() => {
        languageQuestion.setState(setLanguageQuestion)
        languageQuestion.load({
            id: null,
            title: "Seleziona una lingua",
            choices: {
                IT: "Italiano",
                EN: "Inglese",
            },
            acceptedChoices: 1
        })
        // section.push((props) => <Language {...props} />)
        experienceQuestion.setState(setExperienceQuestion)
        experienceQuestion.load(() => {
            let i = 0
            for(let question of experienceQuestion.getQuestions()) {
                // section.push((props) => <ExperienceQuestionLayout question={question} {...props} />)
                i++;
            }
            setSection(section)
        })
        // meetingQuestion.setState(setMeetingQuestion)
        //settingQuestions
        let tmpQuestions = [
            {
                id: null,
                title: "Scegli tu da quale argomento iniziare!",
                choices: {
                    1: "Mercato azionario",
                    2: "crisi economiche",
                    3: "Analisi tecnica",
                    4: "Forex",
                    5: "Obbligazioni",
                    6: "Blockchain & Crypto",
                    7: "Derivati",
                    8: "Fondi & ETF"
                },
                acceptedChoices: -1
            },
            {
                id: null,
                title: "Quanto pensi di conoscere il mondo della finanza?",
                choices: {
                    1: "Poco",
                    2: "Abbastanza",
                    3: "Molto"
                },
                acceptedChoices: 1
            },
            {
                id: null,
                title: "Quanto tempo vuoi dedicare allo studio?",
                choices: {
                    1: "10 minuti",
                    2: "15 minuti",
                    3: "20 minuti"
                },
                acceptedChoices: 1
            },
            {
                id: null,
                title: "Quanto vuoi diventare bravo?",
                choices: {
                    1: "Lupo di Wall Street",
                    2: "Abile investitore",
                    3: "Broker Junior",
                    4: "Commentatore alle cene di Natale"
                },
                acceptedChoices: 1
            },
            {
                id: null,
                title: "Per curiosità, come ci hai conosciuto?",
                choices: {
                    1: "Social Media",
                    2: "Eventi",
                    3: "Passaparola",
                    4: "Pubblicità"
                },
                acceptedChoices: 1
            }

        ]
        for(let questionInfo of tmpQuestions){
            let tmpQuestion = new QuestionController()
            tmpQuestion.load(questionInfo)
            meetingQuestions.push(tmpQuestion)
            section.push((props) => <MeetingQuestionSection {...props} meetingQuestion={tmpQuestion}/>)
        }
        // section.push((props) => <InvitationCodeLayout {...props} />)
        setSection(section)
    }, [])

    return (
        <div onKeyDown={(e) => e.key == "Enter" ? scrollQuestion(1) : null}>
            <h1>Benvenuto</h1>
            {   
                section[currentSection] !== undefined ? 
                section[currentSection]({user:user, setStatus:setStatus, languageQuestion:languageQuestion}) :  //DA SISTEMARE
                ""
            }
            <br />
            {
                currentSection > 0 ? 
                <button className="button bounce" onClick={() => scrollQuestion(-1)}>Indietro</button> :
                ""
            }
            {
                loading ?
                <div className="centered"><div className="loading"></div></div> : 
                <button className="button bounce" onClick={() => scrollQuestion(1)}>
                    {
                        currentSection >= section.length - 1 ?
                        "Fine" : 
                        "Avanti"
                    }
                </button>
            }
            <p className="thin_orange" onClick={() => {
                    setIsSigninIn(false)
                    user.makeUserFieldEmpty()
                }}>Oppure accedi</p>
            {
                showPopup ?
                <Popup isError={status.error} message={status.description} removeFunction={() => setShowPopup(false)}/>:
                ""
            }
        </div>
    )
}

function NameSurnameBirthday(props) {
    let user = props.user
    let setStatus = props.setStatus
    const [birthdayInputType, setBirthdayInputType] = useState('text')

    useEffect(() => {
        if(user.getName() != "" && user.getSurname() != "" && user.getBirthday() != "") {
            if(user.isValidBirthday()) setStatus({error: false})
            else setStatus({
                error: true,
                description: "Data di nascita non valida"
            })
        }
        else setStatus({
            error: true,
            description: "Riempi tutti i campi"
        })
    }, [user.getName(), user.getSurname(), user.getBirthday()])

    return <>
        <p className="description">Iniziamo con qualche informazione su di te</p>
        <br />
        <input
        className="input"
        placeholder="Nome"
        autoFocus={true}
        value={user.getName()}
        onChange={(e) => user.setName(e.target.value)}
        />
        <br/>
        <input
        className="input"
        placeholder="Cognome"
        value={user.getSurname()}
        onChange={(e) => user.setSurname(e.target.value)}
        />
        <br/>
        <input
        className="input"
        type={birthdayInputType}
        onFocus={() => setBirthdayInputType('date')}
        onBlur={() => setBirthdayInputType('text')}
        placeholder="Data di nascita"
        value={user.getBirthday()}
        onChange={(e) => user.setBirthday(e.target.value)}
        />
        <br />
    </>
}

function EmailUsernamePassword(props) {
    let user = props.user
    let setStatus = props.setStatus
    const [viewPassword, setViewPassword] = useState(false)
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState("")

    useEffect(() => {
        if(user.getEmail() != "" &&
           user.getUsername() != "" &&
           user.getPassword() != "" &&
           passwordConfirm != "") {
                if(user.isValidEmail()) {
                    if(user.isValidUsername()) {
                        if(user.getPassword() == passwordConfirm) {
                            setStatus({error: false})
                        } else {
                            setStatus({
                                error: true,
                                description: "Le password non coincidono"
                            })
                        }
                    } else {
                        setStatus({
                            error: true,
                            description: "Username gia esistente"
                        })
                    }
                } else {
                    setStatus({
                        error: true,
                        description: "Formato email non valido"
                    })
                }
                
           }
        else {
            setStatus( {
                error: true,
                description: "Riempi tutti i campi"
            })
        }
    }, [user.getEmail(), user.getUsername(), user.getPassword(), passwordConfirm])

    return <>
        <p className="description">Iniziamo con qualche informazione su di te</p>
        <br />
        <input
        className="input"
        placeholder="Email"
        autoFocus={true}
        value={user.getEmail()}
        onChange={(e) => user.setEmail(e.target.value.replaceAll(" ", ""))}
        />
        <br/>
        <input
        className="input"
        placeholder="Username"
        value={user.getUsername()}
        onChange={(e) => user.setUsername(e.target.value)}
        />
        <br />
        <input
        className="input"
        placeholder="Password"
        type={viewPassword ? "text" : "password"}
        value={user.getPassword()}
        onChange={(e) => user.setPassword(e.target.value)}
        />
        <i>
        {
            viewPassword ?
                <VisibilityIcon className="orange_icon" onClick={() => setViewPassword(false)}/> :
                <VisibilityOffIcon className="orange_icon" onClick={() => setViewPassword(true)}/> 
        }
        </i>
        <br />
        <input
        className="input"
        placeholder="Conferma password"
        type={viewConfirmPassword ? "text" : "password"}
        value={passwordConfirm}
        onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        <i>
        {
            viewConfirmPassword ?
                <VisibilityIcon className="orange_icon" onClick={() => setViewConfirmPassword(false)}/> :
                <VisibilityOffIcon className="orange_icon" onClick={() => setViewConfirmPassword(true)}/> 
        }
        </i>
        <p className="description" style={{ color: "var(--details_color)", fontSize: "12px" }}>* la password deve essere lunga almeno 8 caratteri e deve contenere: maiuscole, minuscole, numeri, e caratteri speciali(_, @, $, .)</p>
    </>
}

function ActivationSection() {
    return (
        <div className="activation_section">
            <h1>Registrazione completata!</h1>
            <p className="description">Ti abbiamo inviato una mail per attivare il tuo account!</p>
        </div>
    )
}

function Language(props) {
    let question = props.languageQuestion
    let setStatus = props.setStatus

    useEffect(() => {
        if(question.areChoicesSelected()) setStatus({error: false})
        else setStatus({
            error: true,
            description: "Selezionare una voce"
        })
    }, [question])

    return <>
        <p className="description">{question.getTitle()}</p>
        <br />
        <MultipleChoiceQuestion question={question} />
    </>
}

function ExperienceQuestionLayout(props) {
    const [question, setQuestion] = useState(props.question)
    let setStatus = props.setStatus

    useEffect(() => {
        question.setState(setQuestion)
    }, [])
    
    useEffect(() => {
        setQuestion(props.question)
    }, [props.question])

    useEffect(() => {
        question.setState(setQuestion)
        if(question.areChoicesSelected()) setStatus({error: false})
        else setStatus({
            error: true,
            description: "Selezionare una voce"
        })
    }, [question])
    
    return <>
        <p className="description">{question.getTitle()}</p>
        <br />
        <MultipleChoiceQuestion question={question} />
    </>
}

function MeetingQuestionSection(props) {
    let question = props.meetingQuestion
    const [veryUnpleasantSolution, setVeryUnpleasantSolution] = useState(1)
    let setStatus = props.setStatus
    useEffect(() => {
        if(question.areChoicesSelected()) setStatus({error: false})
        else setStatus({
            error: true,
            description: "Selezionare una voce"
        })
    }, [veryUnpleasantSolution])

    return <>
        <p className="description">{question.getTitle()}</p>
        <br />
        <MultipleChoiceQuestion question={question} setStatus={setVeryUnpleasantSolution}/>
    </>
}

// function InvitationCodeLayout(props) {
//     let user = props.user
//     let setStatus = props.setStatus

//     return (
//         <>
//         <br/>
//         <p className="description">Hai un codice d'invito? Inseriscilo!</p>
//         <br/>
//         <input
//             className="input"
//             placeholder="Codice d'invito"
//             type="password"
//             value={user && user.getCodiceInvito()}
//             onChange={(e) => user && user.setCodiceInvito(e.target.value)}
//             />
//         <br/>
//         </>
//     )
// }


export default SigninSection
