import News from "../models/news"
import CommentController from "./comment_controller"
import {api_url} from "../App"
import $ from "jquery"
import moment from "moment"
import { beforeSendCallback } from "../utils"

class NewsController extends News{
    constructor() {
        super()
        this.state = undefined
        this.overrideState = undefined
    }

    setState(state) {
        this.state = state
    }

    setOverrideState(callback) {
        this.overrideState = callback
    } 

    updateState() {
        if(this.state != undefined) {
            let newIntstance = new NewsController()
            Object.assign(newIntstance, this)
            this.state(newIntstance)
        } else if(this.overrideState != undefined) {
            this.overrideState()
        }
    }

    loadInfo({id = "", title = "", description = "", publishDate = "", author = "", category = {}, content = "", wallpaper = "", socialPage = "", comments = [], comment_page_index = 1, isSaved = false, isLiked = false}) {
        this.id = id
        this.title = title
        this.description = description
        this.publishDate = publishDate
        this.author = author
        this.category = category
        this.content = content
        this.wallpaper = wallpaper
        this.socialPage = socialPage
        this.comments = comments
        this.comment_page_index = comment_page_index
        this.isSaved = isSaved  
        this.isLiked = isLiked
    }

    load(info) {
        this.loadInfo(info)
        this.updateState()
    }

    async loadById(slug) {
        //let accessToken = window.localStorage.getItem("accessToken")

        return $.ajax({
            type: "GET",
            url: api_url + "news/article/" + slug,
            accepts: "application/json",
            contentType: "application/json",
            beforeSend: beforeSendCallback,
            success: (data) => {
                let info = data['news']
                let auto_save = false
                let author = data['news']['author']['personalData']
                this.setAuthor(author['surname'] + " " + author['name'], auto_save)
                this.setCategory(info['categories'], auto_save)
                this.setTitle(info['title'], auto_save)
                this.setWallpaper(info['coverImageLink'])
                this.setDescription(info['description'], auto_save)
                this.setContent(info['content'], auto_save)
                this.setId(info['slug'], auto_save)
                this.setPublishDate(moment(info['uploadDate']).format("DD-MM-YYYY HH:mm"), auto_save)
                this.setIsLiked(data['isLiked'], auto_save)
                this.setIsSaved(data['isSaved'], auto_save)
                this.updateState()
            },
            error: (message) => console.log(message)
        })
    }
    
    async loadComments(id = this.id) {
        //let accessToken = window.localStorage.getItem("accessToken")

        if(this.getCommentPageIndex() != null) {
            $.ajax({
                type: "GET",
                url: api_url + "/news/article/" + id + "/comments",
                accepts: "json",
                contentType: "json",
                beforeSend: beforeSendCallback,
                data: {
                    page: this.getCommentPageIndex()
                },
                success: (data) => {
                    let nextPage = data['next_page']
                    this.setCommentPageIndex(nextPage)

                    let commentList = data['comment_list']

                    for(let comment of commentList) {
                        let id = comment['comment_id']
                        let content = comment['comment']
                        let author = comment['author']['personalData']
                        let surname = author['surname']
                        let name = author['name']
                        let username = comment['author']['username']

                        let newComment = new CommentController()
                        newComment.setId(id)
                        newComment.setContent(content)
                        newComment.setAuthor(surname + " " + name)
                        newComment.setAuthorUsername(username)

                        this.comments.push(newComment)
                    }

                    this.updateState()
                }
            })
        }
    }

    async saveNews() {
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "POST",
            url: api_url + "/news/article/" + this.getId() + "/save",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    async unsaveNews() {
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "DELETE",
            url: api_url + "/news/article/" + this.getId() + "/save",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    async likedNews() {
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "POST",
            url: api_url + "/news/article/" + this.getId() + "/like",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    async unlikeNews() {
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "DELETE",
            url: api_url + "/news/article/" + this.getId() + "/like",
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
        })
    }

    // getCategories() {return this.categories}
    getId() { return this.id }
    getTitle() { return this.title }
    getDescription() { return this.description }
    getPublishDate() { return this.publishDate }
    getAuthor() { return this.author }
    getCategory() { return this.category }
    getContent() { return this.content }
    getWallpaper() { return this.wallpaper }
    getSocialPage() { return this.socialPage }
    getComments() { return this.comments }
    getIsLiked() { return this.isLiked }
    getIsSaved() { return this.isSaved }
    getCommentPageIndex() { return this.comment_page_index }

    setId(id, _auto_save = true) {
        this.id = id
        if(_auto_save) this.updateState()
    }
    setTitle(title, _auto_save = true) {
        this.title = title
        if(_auto_save) this.updateState()
    }
    setDescription(description, _auto_save = true) {
        this.description = description
        if(_auto_save) this.updateState()
    }
    setPublishDate(publishDate, _auto_save = true) {
        this.publishDate = publishDate
        if(_auto_save) this.updateState()
    }
    setAuthor(author, _auto_save = true) {
        this.author = author
        if(_auto_save) this.updateState()
    }
    setCategory(category, _auto_save = true) {
        this.category = category
        if(_auto_save) this.updateState()
    }
    setWallpaper(wallpaper, _auto_save = true) {
        this.wallpaper = wallpaper
        if(_auto_save) this.updateState()
    }
    setSocialPage(socialPage, _auto_save = true) {
        this.socialPage = socialPage
        if(_auto_save) this.updateState()
    }
    setComments(comments, _auto_save = true) {
        this.comments = comments
        if(_auto_save) this.updateState()
    }
    setContent(content, _auto_save = true) {
        this.content = content
        if(_auto_save) this.updateState()
    }
    setIsLiked(isLiked, _auto_save = true) {
        this.isLiked = isLiked
        if(_auto_save) this.updateState()
    }
    setIsSaved(isSaved, _auto_save = true) {
        this.isSaved = isSaved
        if(_auto_save) this.updateState()
    }
    setCommentPageIndex(isSaved, _auto_save = true) {
        this.comment_page_index = isSaved
        if(_auto_save) this.updateState()
    }

    exportInfo() {
        return {
            id : this.getId(),
            title : this.getTitle(),
            description : this.getDescription(),
            publishDate : this.getPublishDate(),
            author : this.getAuthor(),
            category : this.getCategory(),
            content : this.getContent(),
            wallpaper : this.getWallpaper(),
            socialPage : this.getSocialPage(),
        }
        
    }

    async addComment(info) {
        /**
         * info = {
         *  id: string
         *  title: string
         *  author: string
         *  content: string
         *  date: string
         * }
         */
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "POST",
            url: api_url + "/news/article/" + this.getId() + "/comments",
            accepts: "application/json",
            contentType: "application/json",
            beforeSend: beforeSendCallback,
            data: JSON.stringify({
                content: info['content']
            }),
            success: (data) => {
                let id = data['comment']['comment_id']
                info['id'] = id
                let _comment = new CommentController()
                _comment.load({...info})
                let newComments = this.getComments()
                newComments.push(_comment)
                this.setComments(newComments)
            }
        })
    }

    removeComment(id) {
        //let accessToken = window.localStorage.getItem("accessToken")

        $.ajax({
            type: "DELETE",
            url: api_url + "/news/articles/comment/" + id,
            accepts: "json",
            contentType: "json",
            beforeSend: beforeSendCallback,
            success: () => {
                let comments = this.getComments()
                let commentsId = comments.map(item => item.getId())
                let commentIndex = commentsId.indexOf(id)
                if(commentIndex > -1) comments.splice(commentIndex, 1)
                this.updateState()
            }
        })
    }

    getComment(commentId) {
        return this.comments.map((comment) => {
            if(comment.getId() == commentId)
                return comment
        })
    }
}

export default NewsController