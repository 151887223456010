import React from 'react'
import routes from "../../pages/dashboard/routes"
import { useNavigate } from 'react-router'

import "./style.css"
import { useEffect } from 'react'
import { useState } from 'react'

function CourseCard(props) {
    let content = props.content
    let windowInfo = props.windowInfo
    let navigate = useNavigate()

    function handleClick() {
        navigate(routes.single_course.path + content.getId())
    }


    return (
        <div onClick={handleClick} className="course_card bounce">
            <img src={content.getWallpaper()} className="img-fluid wallpaper" alt={content.getTitle()}/>
            <div className="space_between date_author">
                {/* <p className="date mb-1">{content.getPublishDate()}</p> */}
                {/* <p className="author mb-1">Di {content.getAuthor()}</p> */}
            </div>
            <h5 className="title m-0">{content.getTitle()}</h5>
            <p className="description">{content.getDescription()}</p>
        </div>
    )
}

function LinkedCourseCard(props){
    let content = props.content
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate()

    useEffect(()=>{
        setLoading(true)
        content.loadById(content.getId())
            .then(()=>{
                setLoading(false)
            })
    },[content])

    function handleClick() {
        if(!loading){
            let [nextLessonChapterID, nextLessonID] = content.getNextLessonId(...content.getLastViewedLesson())
            navigate(routes.single_course.path + content.getId() +'/' + nextLessonChapterID + '/' + nextLessonID)
        }
    }

    useEffect(()=>{
        document.getElementById("playable-"+content.getId()).style.background = "url('"+content.getWallpaper()+"')"
        document.getElementById("playable-"+content.getId()).style.backgroundSize = 'cover'
        
    },[])

    function zeroPad(num, places) {return String(num).padStart(places, '0')}
    function getDescriptionCheckpoint(){
        let [nextLessonChapterID, nextLessonID] = content.getNextLessonId(...content.getLastViewedLesson())
        if(content.getChapterPosition(nextLessonChapterID) && content.getLessonPosition(nextLessonChapterID, nextLessonID))
            return "Riprendi dalla lezione " + zeroPad(content.getChapterPosition(nextLessonChapterID),2) + 'x' + zeroPad(content.getLessonPosition(nextLessonChapterID, nextLessonID),2)
        else return "Loading..."
    }

    return (
        <div onClick={handleClick} className="course_card bounce">
            <div className="playable-img wallpaper" id={"playable-"+content.getId()}>
                <div className="playbtn">
                    {loading?
                        <span style={{"font-size":"28px"}}>loading...</span>
                    :"►"}
                </div>
            </div>
            <div className="space_between date_author">
                {/* <p className="date mb-1">{content.getPublishDate()}</p> */}
                {/* <p className="author mb-1">Di {content.getAuthor()}</p> */}
            </div>
            <h5 className="title m-0">{content.getTitle()}</h5>
            <p className="description">{getDescriptionCheckpoint()}</p>
        </div>
    )
}

export default CourseCard
export {LinkedCourseCard}
